import React from 'react'

import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

import { renderClasses } from '../../utils'

const TYPES = [
    'text',
    'email',
    'tel',
    'password',
    'number',
    'url',
    'checkbox',
    'hidden',
    'textarea',
]

const FormInput = props => {
    const {
        children,
        id,
        form,
        name,
        options = {},
        placeholder = '',
        type = 'text',
        onChange = () => {},
        maxLength = 350,
        ...classProps
    } = props

    if (TYPES.indexOf(type) === -1) {
        throw new Error('Invalid input type for FormInput.')
    }

    let input

    switch (type) {
        case 'radio':
        case 'checkbox':
            input = (
                <div>
                    <input
                        className={renderClasses('form_checkbox', classProps)}
                        type={type}
                        id={id}
                        name={name}
                    />
                    <label className="form_checkboxLabel" htmlFor={id}>
                        {children}
                    </label>
                </div>
            )
            break

        case 'textarea':
            input = (
                <textarea
                    className={renderClasses('form_textarea', classProps)}
                    placeholder={placeholder}
                    id={id}
                    name={name}
                    onChange={onChange}
                    maxLength={maxLength}
                />
            )
            break

        default:
            input = (
                <input
                    className={renderClasses('form_input', classProps)}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    name={name}
                />
            )
    }

    if (form) {
        return form.getFieldDecorator(name, options)(input)
    } else {
        return input
    }
}

FormInput.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    form: formShape,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.object,
    placeholder: PropTypes.string,
    type: PropTypes.string,
}

export default FormInput
