import { useEffect, useReducer } from 'react'
import _ from 'lodash'

const initialState = {
    items: [],
    error: null,
    requestStatus: null,
    isLoading: true,
}

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return {
                ...state,
                items: [],
                isLoading: true,
            }
        case 'FETCH_SUCCESS':
            return {
                ...state,
                items: action.payload,
                isLoading: false,
            }
        case 'FETCH_FAILURE':
            return {
                ...state,
                error: action.payload.data,
                requestStatus: action.payload.status,
                isLoading: false,
            }
        case 'NO_FETCH':
            return {
                ...initialState,
                isLoading: false,
            }
        default:
            throw new Error('Invalid action type.')
    }
}

/**
 * Retrieve data based on action using a reducer
 *
 * @param  {Function}  fnAction
 * @return {Object}
 */
export const useFetchItems = fnAction => {
    const [state, dispatch] = useReducer(dataFetchReducer, initialState)

    useEffect(() => {
        let didCancel = false
        const fetchData = async () => {
            dispatch({ type: 'FETCH_REQUEST' })
            try {
                const result = await fnAction()

                // for functions that are not noop
                // used to dispatch a specific type
                // mostly used for NO_FETCH type
                if (_.isFunction(result) && !_.isUndefined(result())) {
                    dispatch(result())
                }

                // check on result.data for noop function
                // avoiding to dispatch empty success on reducer
                if (!didCancel && !_.isNil(result.data)) {
                    dispatch({ type: 'FETCH_SUCCESS', payload: result.data })
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: 'FETCH_FAILURE', payload: error.response })
                }
            }
        }

        fetchData()

        return () => {
            didCancel = true
        }
    }, [fnAction])

    return state
}

/**
 * Rules to check on merging multiple object states from dataFetchReducer
 *
 * @param  {Object}  objValue
 * @param  {Object}  srcValue
 * @param  {String}  key
 * @param  {Object}  object
 * @param  {Object}  source
 * @param  {Object}  stack
 */
export const responseCustomizer = (objValue, srcValue, key, object, source, stack) => {
    switch (key) {
        case 'isLoading':
            if (objValue || srcValue) return true
            break
        case 'error':
            if (objValue || srcValue) {
                let messages = []
                if (objValue) messages.push(objValue)
                if (srcValue) messages.push(srcValue)
                return _.flatten(messages)
            }
            break
        case 'items':
        default:
            break
    }
}

export const logErrors = errors => {
    if (_.isNull(errors)) {
        return () => {}
    }
    return () => {
        if (_.isArray(errors)) {
            errors.map((err, i) => {
                if (err.message) {
                    console.error(err.message)
                } else {
                    console.error(err)
                }
                return false
            })
        } else {
            if (errors.message) {
                console.error(errors.message)
            } else {
                console.error(errors)
            }
        }
    }
}
