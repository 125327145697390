import React from 'react'
import _ from 'lodash'
import { getActiveLanguage } from 'react-localize-redux'
import { matchPath } from 'react-router-dom'

import { getStore } from '../store'

import { Translation } from '../components/Translation'
import { localizePath, translation, normalizePath } from '.'

export const resolveServiceLink = (service, label = '') => {
    const linkLabel = _.isEmpty(label) ? service.name : label

    let serviceLink = {
        label: _.isObject(linkLabel) ? <Translation value={linkLabel} /> : linkLabel,
        url: localizePath(service.slug),
        external: false,
    }

    if (service.type === 'city/hub/service/external-link') {
        serviceLink.url = localizePath(service.options.url)
        serviceLink.external = true
    }
    return serviceLink
}

export const findServiceByType = (services = [], type) => {
    return _.find(services, service => service.type === type)
}

export const findServiceBySlug = (services = [], slug) => {
    return _.find(services, service => `/${translation(service.slug)}` === slug)
}

/**
 * Retrieve the related service type from route path
 */
export const getServiceTypeFromRoutePath = path => {
    const routes = getStore().getState().routes
    const { code: lang } = getActiveLanguage(getStore().getState().localize)

    for (const route of routes) {
        if (
            !_.isUndefined(route.service) &&
            !_.isUndefined(route.path[lang]) &&
            matchPath(normalizePath(path), { path: route.path[lang], exact: true })
        ) {
            return route.service
        }
    }
}
