import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { isTranslationObject, localizePath, resolveServiceLink, translation } from '../../utils'
import { Icon } from '../Icon'
import { Link } from '../Link'
import { Translation } from '../Translation'
import { FINISHED_CONTENT } from '../../config'
import { Button } from '../Button'

const NavItem = ({ currentService, item }) => {
    const isSelected =
        (!_.isUndefined(currentService) &&
            (item === currentService || _.find(item.services, currentService))) ||
        false
    const [isOpen, setIsOpen] = useState(isSelected ? true : false)

    let content
    if (item.services) {
        content = (
            <div>
                <button
                    className="nav_link -dropdown"
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                >
                    <span className="nav_link_plus"></span>
                    <Translation value={item.name} />
                </button>

                <ul className="nav_dropdown">
                    {item.services.map((service, i) => {
                        const { label, url, external } = resolveServiceLink(service)

                        return (
                            <li key={i}>
                                <Link
                                    className={`nav_dropdown_link ${
                                        currentService === service ? 'is-current' : ''
                                    }`}
                                    href={url}
                                    external={external}
                                >
                                    {label}
                                    {external && <Icon name="external" />}
                                </Link>
                            </li>
                        )
                    })}
                    {item.slug &&
                        <li>
                            <Link
                                className="nav_dropdown_link -blue"
                                href={localizePath(item.slug)}
                            >
                                <Translation value="app.seeMore" />
                            </Link>
                        </li>
                    }
                </ul>
            </div>
        )
    } else {
        const { label, url, external } = resolveServiceLink(item)

        content = (
            <Link
                className={classNames('nav_link', { 'is-current': isSelected })}
                href={url}
                external={external}
            >
                {label}
            </Link>
        )
    }

    return <div className={classNames('nav_item', { 'is-open': isOpen })}>{content}</div>
}

NavItem.propTypes = {
    item: PropTypes.shape({
        name: isTranslationObject,
        services: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    currentService: PropTypes.object,
}

export default NavItem
