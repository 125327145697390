import React from 'react'
import PropTypes from 'prop-types'
import { isTranslationObject, localizePath } from '../../../../utils'

import { Wrapper } from '../../../../components/Wrapper'
import { Section, SectionHeader } from '../../../../components/Section'
import { Heading } from '../../../../components/Heading'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Translation } from '../../../../components/Translation'
import { ServiceCard } from '.'
import { Button } from '../../../../components/Button'

const ServiceList = ({ name, services, options }) => {
    const renderedServices = services.map((service, i) => {
        return (
            <LayoutItem utility={['1/2@from-small']} key={i}>
                <ServiceCard key={service.id} {...service} />
            </LayoutItem>
        )
    })

    const seeAllButton = options

    return (
        <Wrapper>
            <Section>
                <SectionHeader>
                    <Heading option={['h3']} el="h2">
                        <Translation value={name} />
                    </Heading>
                    {seeAllButton && seeAllButton.url && (
                        <Button
                            option={['small', 'gray', 'iconRight']}
                            icon="arrow-right"
                            href={localizePath(seeAllButton.url)}
                        >
                            { seeAllButton.label && <Translation value={seeAllButton.label} />}
                        </Button>
                    )}
                </SectionHeader>
                <Layout option={['flex', 'stretch', 'gutterSmall', 'gutterBottom']}>
                    {renderedServices}
                </Layout>
            </Section>
        </Wrapper>
    )
}

ServiceList.propTypes = {
    name: isTranslationObject,
    services: PropTypes.array.isRequired,
}

export default ServiceList
