export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX'
export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX'

export const openLightbox = (urlToMediumImageFile,urlToLargeImageFile,alt) => {
    return {
        type: OPEN_LIGHTBOX,
        urlToMediumImageFile,
        urlToLargeImageFile,
        alt,
    }
}

export const closeLightbox = () => {
    return {
        type: CLOSE_LIGHTBOX,
    }
}
