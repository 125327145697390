import React from 'react'

import { HeaderPage } from '../../../components/HeaderPage'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { View } from '../../../components/View'
import { Wrapper } from '../../../components/Wrapper'

import { ParkingCiviliaContainer } from '../components/ParkingCivilia'

const ParkingCiviliaView = ({ location, serviceData, serviceCategoryData }) => (
    <View>
        <ServiceMetatags data={serviceData} />
        <HeaderPage
            heading="parking.civilia.header"
            breadcrumb={serviceCategoryData.name}
            icon={serviceData.icon}
            introBox={serviceData.introBox}
        />

        <Wrapper>
            <ParkingCiviliaContainer />
        </Wrapper>
    </View>
)

export default ParkingCiviliaView
