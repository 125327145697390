import React, { useCallback } from 'react'
import { connect } from 'react-redux'
// import { SwitchTransition } from 'react-transition-group'
import _ from 'lodash'

// import { Fade } from '../../../../components/Fade'
import { parseServiceCategories } from '../../../../utils'

import { ServiceDashboard } from './index'

const ServiceDashboardContainer = ({
    setIsLoaded = () => {},
    serviceCategories,
    services,
    categoryId = false
}) => {
    const cleanedServices = _.filter(
        services,
        service => !_.isNull(service.category)
    )

    let items = parseServiceCategories(serviceCategories, cleanedServices)
    if (categoryId) {
        items = items.filter(({id}) => id === categoryId)
    }

    return <ServiceDashboard servicesCategories={items} dashboardLoaded={() => setIsLoaded(true)}/>
}

const mapStateToProps = state => ({
    serviceCategories: state.hub.serviceCategories.entities,
    services: state.hub.services.entities,
})

export default connect(mapStateToProps)(ServiceDashboardContainer)
