import React, { useCallback } from 'react'
import { SwitchTransition } from 'react-transition-group'
import _ from 'lodash'

import { Fade } from '../../../../components/Fade'
import { NotFound } from '../../../../components/NotFound'
import { useDev, logErrors, useFetchItems } from '../../../../utils'

import { fetchProjectNewsBySlug } from '../../actions/projectNewsActions'
import { ProjectNewsDetails } from './'

const ProjectNewsDetailsContainer = ({ slug }) => {
    // prepare function to retrieve project news by slug
    const useFetchProjectNews = useCallback(() => {
        return fetchProjectNewsBySlug(slug)
    }, [slug])

    const { items, isLoading, requestStatus, error } = useFetchItems(useFetchProjectNews)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <ProjectNewsDetails.Placeholder />
                ) : (
                    <ProjectNewsDetails projectNews={items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default ProjectNewsDetailsContainer
