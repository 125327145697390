import React, { useState } from 'react'
import { connect } from 'react-redux'

import { SwitchTransition } from 'react-transition-group'

import { View } from '../../../components/View'
import { Fade } from '../../../components/Fade'
import { ServiceDashboardContainer } from '../components/ServiceDashboard'
import { HeaderPage } from '../../../components/HeaderPage'

const ServiceCategoryView = ({ categoryId, categoryData }) => {
    // retrieve directly by redux store by default or retrieve local storage for on any action in page.
    const [isDashboardLoaded, setDashboardLoaded] = useState(false)

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isDashboardLoaded}>
                <React.Fragment>
                    <View>
                        <HeaderPage
                            heading={categoryData.name}
                            breadcrumb="Services"
                            icon={categoryData.icon}
                            introBox={categoryData.introBox}
                        />
                        <ServiceDashboardContainer
                            setIsLoaded={param => setDashboardLoaded(param)}
                            categoryId={categoryId}
                        />
                    </View>
                </React.Fragment>
            </Fade>
        </SwitchTransition>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
})

export default connect(mapStateToProps)(ServiceCategoryView)
