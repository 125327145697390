import { apiClient } from '../../../actions'

import { API_URL } from '../config'

const baseUrl = `${API_URL}/projects/news`

/**
 * Retrieve project's news details by slug.
 */
export const fetchProjectNewsBySlug = (slug, config = {}) => {
    return apiClient.get(`${baseUrl}?slug=${slug}`, config)
}
