import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { LocalizeProvider, getActiveLanguage } from 'react-localize-redux'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import '../Style/index.scss'

import { viewMap as appViewMap } from '../../config/mappings'
import appRoutes from '../../config/routes.json'
import { resolveMappedComponent, translation } from '../../utils'
import * as appViews from '../../views'
import modules from '../../modules'
import { ErrorBoundary } from '../ErrorBoundary'
import { RouteResolver } from '../RouteResolver'

/**
 * Holds all the application's view components, starting with agora-app views.
 * Used by the resolver for magic-matching static route definitions.
 *
 * @type {Object}
 */
let views = { ...appViews }

/**
 * Holds all the view component mappings, starting with agora-app's mappings.
 * Used for mapping an identifier given by the API to a view component.
 *
 * @type {Object}
 */
let viewMap = { ...appViewMap }

// Loop the supported modules...
for (let module of modules) {
    // ...to extract their static route definitions...
    _.extend(views, module.views)
    // ...and their view component mappings.
    _.extend(viewMap, module.mappings.viewMap)
}

const mapStateToRoutes = state => {
    const services = state.hub.services.entities
    const serviceCategories = state.hub.serviceCategories.entities

    const views = [
        ..._.map(services, item => {
            item.mapProps = (item, state) => mapServiceProps(item, state)
            return item
        }),
        ..._.map(serviceCategories, item => {
            item.mapProps = (item, state) => mapServiceCategoryProps(item, state)
            return item
        }),
    ]

    /**
     * Holds all the dynamic routes definitions as extracted from the application's
     * available services.
     *
     * @type {Array}
     */
    const routes = _.compact(
        _.map(views, view => {
            const { type: viewKey } = view
            const resolvedComponent = resolveMappedComponent(viewKey, viewMap)
            if (resolvedComponent === null) {
                return resolvedComponent
            }

            // Return the view component and its path in the format expected by createRoutes.
            // No ID is needed as we're not rendering the route using a magic-matched component.
            return {
                component: resolvedComponent,
                path: view.slug,
                props: view.mapProps(view, state),
            }
        })
    )

    return [...appRoutes, ...state.routes, ...routes]
}

const mapServiceProps = (service, state) => {
    return {
        serviceId: service.id,
        serviceData: service,
        serviceCategoryData: _.find(
            state.hub.serviceCategories.entities,
            serviceCategory => serviceCategory.id === service.category
        ),
    }
}

const mapServiceCategoryProps = (category, state) => {
    return {
        categoryId: category.id,
        categoryData: category,
    }
}

const App = ({ helmetContext, store }) => {
    const localizeState = store.getState().localize
    const isSsr = store.getState().app.isSsr
    const { code: lang } = getActiveLanguage(localizeState)
    const localizeProviderProps = isSsr
        ? {
              store,
              initialize: {
                  languages: localizeState.languages,
                  translation: localizeState.translations,
                  options: {
                      ...localizeState.options,
                      renderToStaticMarkup: renderToStaticMarkup,
                  },
              },
          }
        : { store }

    // We only need to do this once since routes will not change as the app is used.
    const routes = mapStateToRoutes(store.getState())

    return (
        <HelmetProvider context={helmetContext}>
            <Helmet
                defaultTitle={translation('app.agora.title')}
                titleTemplate={`%s | ${translation('app.agora.title')}`}
            >
                <html lang={lang} />
                <meta name="description" content={translation('app.agora.description')} />
            </Helmet>
            <LocalizeProvider {...localizeProviderProps}>
                <ErrorBoundary>
                    <RouteResolver routes={routes} views={views} />
                </ErrorBoundary>
            </LocalizeProvider>
        </HelmetProvider>
    )
}

App.propTypes = {
    helmetContext: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
}

export default App
