import React from 'react'

import { HeaderPage } from '../../../components/HeaderPage'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { View } from '../../../components/View'

import { PermitRequestDetailsContainer } from '../components/Permit'

const PermitRequestDetailsView = ({ serviceData, serviceCategoryData }) => (
    <View>
        <ServiceMetatags data={serviceData} />
        <HeaderPage
            heading={serviceData.name}
            breadcrumb={serviceCategoryData.name}
            icon={serviceData.icon}
            introBox={serviceData.introBox}
        />
        <PermitRequestDetailsContainer serviceData={serviceData} />
    </View>
)

export default PermitRequestDetailsView
