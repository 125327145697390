import React from 'react'
import PropTypes from 'prop-types'

import { Link } from '../../../../components/Link'
import { Heading } from '../../../../components/Heading'
import { Translation } from '../../../../components/Translation'
import { renderRoute } from '../../../../utils'

const Profile = ({ isHidden = false, logout, user }) => {
    if (isHidden) {
        return null
    }

    return (
        <React.Fragment>
            <Heading option={['h2']}>{user.fullName}</Heading>
            <div className="sidebar_list">
                <div className="sidebar_list_item">
                    <Link option={['black']} route="hub-edit-profile">
                        <Translation value="hub.profile.modify" />
                    </Link>
                </div>
                {renderRoute('memo-subscription') && (
                    <div className="sidebar_list_item">
                        <Link option={['black']} route="memo-subscription">
                            {user.memoSubscription ? (
                                <Translation value="memo.profile.modify" />
                            ) : (
                                <Translation value="memo.profile.create" />
                            )}
                        </Link>
                    </div>
                )}
                <div className="sidebar_list_item">
                    <Link onClick={logout}>
                        <Translation value="hub.profile.logout" />
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

Profile.propTypes = {
    isHidden: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default Profile
