import { HeaderHeroGuest } from '../components/HeaderHero'
import { HeaderHero } from '../components/HeaderHero'
import { ServiceList } from '../components/Service'
import { RegisterPanel } from '../components/RegisterPanel'
import GenericView from '../views/GenericView'
import ServiceCategoryView from '../views/ServiceCategoryView'

export const componentMap = {
    'city/hub/widget/home/hero-welcome': HeaderHero,
    'city/hub/widget/home/hero-welcome-guest': HeaderHeroGuest,
    'city/hub/widget/home/service-list': ServiceList,
    'city/hub/widget/home/register': RegisterPanel,
}

export const viewMap = {
    'city/hub/service/generic': GenericView,
    'city/hub/service/category': ServiceCategoryView,
}
