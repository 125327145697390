import React from 'react'
import { ButtonCheckbox } from '../../../../components/Button'
import { FormItem } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Button } from '../../../../components/Button'
import { Wrapper } from '../../../../components/Wrapper'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { translation } from '../../../../utils'

import { connect } from 'react-redux'
import { openLightbox } from '../../../../actions/lightboxActions'

const QuestionChoiceRadio = ({
    question: { id, choices, required },
    form,
    form: { getFieldValue, getFieldError, setFieldsInitialValue, isFieldTouched },
    formValues = {},
    handleOpenLightbox
}) => {
    let isImages = false
    choices.map(choice => {
        if (choice.image) {
            isImages = true
        }
        return null
    })

    const getChoice = hasImage =>
        choices.map(choice => {
            let result = (
                <FormItem option={['medium', 'anim']} key={choice.id}>
                    {choice.image &&
                        <Button onClick={() => { handleOpenLightbox(translation(choice.image),translation(choice.image),false) }} option={['white', 'circle', 'icon', 'small', 'zoom']} icon="search"/>
                    }

                    <ButtonCheckbox
                        id={choice.id}
                        type="radio"
                        form={form}
                        status={[getFieldError(id) && 'error', choice.image ? 'image' : '']}
                        value={choice.id}
                        option={['full']}
                        checked={formValues[id] === choice.id && true}
                        name={id}
                        options={{
                            preserve: getFieldValue(id) ? true : false,
                            validate: [
                                {
                                    trigger: 'onChange',
                                    rules: [
                                        {
                                            required: required,
                                        },
                                    ],
                                },
                            ],
                        }}
                    >
                        {choice.image &&
                            <img src={translation(choice.image)} alt="" />
                        }
                        <Translation value={choice.content} isHtml />
                    </ButtonCheckbox>
                </FormItem>
            )

            if (hasImage) {
                result = (
                    <LayoutItem utility={['1/2@from-small']} key={choice.id}>
                        {result}
                    </LayoutItem>
                )
            }
            return result
        })

    return isImages ? (
        <Wrapper>
            <Layout option={['gutterSmall']}>{getChoice('image')}</Layout>
        </Wrapper>
    ) : (
        <Wrapper option={['small']}>{getChoice()}</Wrapper>
    )
}

const mapDispatchToProps = dispatch => ({
    handleOpenLightbox: (urlToMediumImageFile,urlToLargeImageFile,alt) => dispatch(openLightbox(urlToMediumImageFile,urlToLargeImageFile,alt)),
})

export default connect(null, mapDispatchToProps)(QuestionChoiceRadio)
