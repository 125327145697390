import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { formShape } from 'rc-form'

import { FormInput, FormItem, FormLabel } from '../../../../components/Form'
import { CardIcon } from '../../../../components/CardIcon'
import { ButtonCheckbox } from '../../../../components/Button'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'
import { CardHeader } from '../../../../components/Card'
import { Button } from '../../../../components/Button'

import { checkDuplicate } from '../../actions/subscriptionActions'

const SubscriptionFormContactMethod = ({
    isAuthenticated,
    memo,
    memoSettings,
    translate,
    form,
    form: { getFieldError, getFieldValue, isFieldTouched /*validateFields */ },
    formValues,
    user: { email },
    // default local storage user email from subscription
    userEmail,
}) => {
    const EMAIL_FIELD = 'email'
    const EMAIL_ENABLED_FIELD = 'emailEnabled'
    const SMS_FIELD = 'smsPhone'
    const SMS_ENABLED_FIELD = 'smsEnabled'
    const VOICE_FIELD = 'voicePhone'
    const VOICE_ENABLED_FIELD = 'voiceEnabled'

    const checkDuplicateByType = async (type, value) => {
        if (isAuthenticated && memo[type] === value) {
            return true
        }

        let data = {}
        data[type] = value

        const response = await checkDuplicate(data, {
            cancelToken: axios.CancelToken.source().token,
        })
            .then(res => !res.data.email)
            .catch(err => false)

        return new Promise((resolve, reject) => {
            if (response) resolve()
            else reject(false)
        })
    }

    const {
        general: { emailEnabled, smsEnabled, voiceEnabled },
        alert: { defaultEmailEnabled, defaultSmsEnabled, defaultVoiceEnabled },
    } = memoSettings

    const isEmailChecked =
        isFieldTouched(EMAIL_ENABLED_FIELD) && !_.isEmpty(getFieldValue(EMAIL_ENABLED_FIELD))
            ? getFieldValue(EMAIL_ENABLED_FIELD)
            : formValues[EMAIL_ENABLED_FIELD]
            ? formValues[EMAIL_ENABLED_FIELD]
            : isAuthenticated && !_.isUndefined(memo[EMAIL_ENABLED_FIELD])
            ? memo[EMAIL_ENABLED_FIELD]
            : defaultEmailEnabled

    const isSmsChecked =
        isFieldTouched(SMS_ENABLED_FIELD) && !_.isEmpty(getFieldValue(SMS_ENABLED_FIELD))
            ? getFieldValue(SMS_ENABLED_FIELD)
            : formValues[SMS_ENABLED_FIELD]
            ? formValues[SMS_ENABLED_FIELD]
            : isAuthenticated && !_.isUndefined(memo[SMS_ENABLED_FIELD])
            ? memo[SMS_ENABLED_FIELD]
            : defaultSmsEnabled

    const isVoiceChecked =
        isFieldTouched(VOICE_ENABLED_FIELD) && !_.isEmpty(getFieldValue(VOICE_ENABLED_FIELD))
            ? getFieldValue(VOICE_ENABLED_FIELD)
            : formValues[VOICE_ENABLED_FIELD]
            ? formValues[VOICE_ENABLED_FIELD]
            : isAuthenticated && !_.isUndefined(memo[VOICE_ENABLED_FIELD])
            ? memo[VOICE_ENABLED_FIELD]
            : defaultVoiceEnabled

    const [hasSms, setSms] = useState(isSmsChecked)
    const [hasVoice, setVoice] = useState(isVoiceChecked)

    return (
        <div>
            <Wrapper option={['small', 'noPaddingSmall']}>
                <CardHeader
                    option={['noMargin']}
                    heading="memo.subscription.contact.title"
                    description="memo.subscription.contact.description"
                />
            </Wrapper>
            <Wrapper option={['medium', 'noPaddingSmall']}>
                {emailEnabled && (
                    <CardIcon
                        icon="email"
                        heading="memo.subscription.contact.email.title"
                        description="memo.subscription.contact.email.description"
                    >
                        <FormItem className="u-none">
                            <FormLabel inputId={EMAIL_FIELD}>
                                <Translation value="memo.subscription.contact.email.label" />*
                            </FormLabel>
                            <FormInput
                                option={['center']}
                                status={[getFieldError(EMAIL_FIELD) && 'error']}
                                form={form}
                                id={EMAIL_FIELD}
                                name={EMAIL_FIELD}
                                options={{
                                    initialValue:
                                        // set form value by default
                                        isFieldTouched(EMAIL_FIELD) &&
                                        !_.isEmpty(getFieldValue(EMAIL_FIELD))
                                            ? getFieldValue(EMAIL_FIELD)
                                            : formValues[EMAIL_FIELD]
                                            ? formValues[EMAIL_FIELD]
                                            : // memo field if connected
                                            isAuthenticated && memo[EMAIL_FIELD]
                                            ? memo[EMAIL_FIELD]
                                            : // user field if connected
                                            isAuthenticated
                                            ? email
                                            : // userEmail from localStorage on redirection from user subscription
                                            !_.isNil(userEmail)
                                            ? userEmail
                                            : '',
                                    validateFirst: true,
                                    validate: [
                                        {
                                            trigger: 'onBlur',
                                            rules: [
                                                {
                                                    required: isFieldTouched(EMAIL_ENABLED_FIELD)
                                                        ? getFieldValue(EMAIL_ENABLED_FIELD)
                                                        : formValues[EMAIL_FIELD]
                                                        ? formValues[EMAIL_FIELD]
                                                        : defaultEmailEnabled
                                                        ? true
                                                        : false,
                                                    message: translate(
                                                        'hub.subscription.errors.required'
                                                    ),
                                                },
                                                {
                                                    type: 'email',
                                                    message: translate(
                                                        'hub.subscription.email.errorValid'
                                                    ),
                                                },
                                                {
                                                    asyncValidator: (rule, value) =>
                                                        checkDuplicateByType(EMAIL_FIELD, value),
                                                    message: translate(
                                                        'hub.subscription.email.errorDuplicate'
                                                    ),
                                                },
                                            ],
                                        },
                                    ],
                                }}
                                type="text"
                                placeholder={translate(
                                    'memo.subscription.contact.email.placeholder'
                                )}
                            />
                        </FormItem>
                        <ButtonCheckbox
                            status={[getFieldError(EMAIL_ENABLED_FIELD) && 'error']}
                            form={form}
                            id={EMAIL_ENABLED_FIELD}
                            name={EMAIL_ENABLED_FIELD}
                            checked={isEmailChecked && true}
                            value={isEmailChecked}
                            disabled
                            options={{
                                initialValue: isEmailChecked,
                            }}
                            type="checkbox"
                        >
                            <Translation value="memo.subscription.contact.email.checkedOption" />
                        </ButtonCheckbox>

                        {isAuthenticated && (
                            <div className="u-margin-top-small">
                                <Button
                                    option={['small', 'gray', 'iconLeft']}
                                    icon="arrow-left"
                                    route="hub-edit-profile"
                                >
                                    <Translation value="memo.profile.changeEmail" />
                                </Button>
                            </div>
                        )}
                    </CardIcon>
                )}

                {smsEnabled && (
                    <CardIcon
                        icon="sms"
                        heading="memo.subscription.contact.sms.title"
                        description="memo.subscription.contact.sms.description"
                    >
                        <ButtonCheckbox
                            status={[getFieldError(SMS_ENABLED_FIELD) && 'error']}
                            form={form}
                            id={SMS_ENABLED_FIELD}
                            name={SMS_ENABLED_FIELD}
                            checked={isSmsChecked && true}
                            value={isSmsChecked}
                            onChange={() => setSms(!hasSms)}
                            options={{
                                initialValue: isSmsChecked,
                            }}
                            type="checkbox"
                        >
                            <Translation value="memo.subscription.contact.sms.checkedOption" />
                        </ButtonCheckbox>
                        <FormItem option={['marginTop']} status={[hasSms ? '' : 'hidden']}>
                            <FormLabel inputId={SMS_FIELD}>
                                <Translation value="memo.subscription.contact.sms.label" />
                            </FormLabel>
                            <FormInput
                                option={['center']}
                                status={[getFieldError(SMS_FIELD) && 'error']}
                                form={form}
                                id={SMS_FIELD}
                                name={SMS_FIELD}
                                options={{
                                    initialValue:
                                        isFieldTouched(SMS_FIELD) &&
                                        !_.isEmpty(getFieldValue(SMS_FIELD))
                                            ? getFieldValue(SMS_FIELD)
                                            : formValues[SMS_FIELD]
                                            ? formValues[SMS_FIELD]
                                            : isAuthenticated && memo[SMS_FIELD]
                                            ? memo[SMS_FIELD]
                                            : '',
                                    validateFirst: true,
                                    validate: [
                                        {
                                            trigger: 'onBlur',
                                            rules: [
                                                {
                                                    required: isFieldTouched(SMS_ENABLED_FIELD)
                                                        ? getFieldValue(SMS_ENABLED_FIELD)
                                                        : isAuthenticated &&
                                                          !_.isUndefined(memo[SMS_FIELD]) &&
                                                          memo[SMS_FIELD]
                                                        ? memo[SMS_FIELD]
                                                        : defaultSmsEnabled
                                                        ? true
                                                        : false,
                                                    message: translate(
                                                        'hub.subscription.errors.required'
                                                    ),
                                                },
                                                {
                                                    pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                                    message: translate(
                                                        'memo.subscription.contact.sms.errorValid'
                                                    ),
                                                },
                                                {
                                                    asyncValidator: (rule, value) =>
                                                        checkDuplicateByType(SMS_FIELD, value),
                                                    message: translate(
                                                        'memo.subscription.contact.sms.errorDuplicate'
                                                    ),
                                                },
                                            ],
                                        },
                                    ],
                                }}
                                type="tel"
                                placeholder={translate('memo.subscription.contact.sms.placeholder')}
                            />
                        </FormItem>
                    </CardIcon>
                )}

                {voiceEnabled && (
                    <div>
                        <CardIcon
                            icon="voice"
                            heading="memo.subscription.contact.voice.title"
                            description="memo.subscription.contact.voice.description"
                        >
                            <ButtonCheckbox
                                status={[getFieldError(VOICE_ENABLED_FIELD) && 'error']}
                                form={form}
                                id={VOICE_ENABLED_FIELD}
                                name={VOICE_ENABLED_FIELD}
                                checked={isVoiceChecked && true}
                                onChange={() => setVoice(!hasVoice)}
                                value={isVoiceChecked}
                                options={{
                                    initialValue: isVoiceChecked,
                                }}
                                type="checkbox"
                            >
                                <Translation value="memo.subscription.contact.voice.checkedOption" />
                            </ButtonCheckbox>
                            <FormItem option={['marginTop']} status={[hasVoice ? '' : 'hidden']}>
                                <FormLabel inputId={VOICE_FIELD}>
                                    <Translation value="memo.subscription.contact.voice.label" />
                                </FormLabel>
                                <FormInput
                                    option={['center']}
                                    status={[getFieldError(VOICE_FIELD) && 'error']}
                                    form={form}
                                    id={VOICE_FIELD}
                                    name={VOICE_FIELD}
                                    options={{
                                        initialValue:
                                            isFieldTouched(VOICE_FIELD) &&
                                            !_.isEmpty(getFieldValue(VOICE_FIELD))
                                                ? getFieldValue(VOICE_FIELD)
                                                : formValues[VOICE_FIELD]
                                                ? formValues[VOICE_FIELD]
                                                : isAuthenticated && memo[VOICE_FIELD]
                                                ? memo[VOICE_FIELD]
                                                : '',
                                        validateFirst: true,
                                        validate: [
                                            {
                                                trigger: 'onBlur',
                                                rules: [
                                                    {
                                                        required: isFieldTouched(
                                                            VOICE_ENABLED_FIELD
                                                        )
                                                            ? getFieldValue(VOICE_ENABLED_FIELD)
                                                            : isAuthenticated &&
                                                              !_.isUndefined(memo[VOICE_FIELD]) &&
                                                              memo[VOICE_FIELD]
                                                            ? memo[VOICE_FIELD]
                                                            : defaultVoiceEnabled
                                                            ? true
                                                            : false,
                                                        message: translate(
                                                            'hub.subscription.errors.required'
                                                        ),
                                                    },
                                                    {
                                                        pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                                        message: translate(
                                                            'memo.subscription.contact.voice.errorValid'
                                                        ),
                                                    },
                                                    {
                                                        asyncValidator: (rule, value) =>
                                                            checkDuplicateByType(
                                                                VOICE_FIELD,
                                                                value
                                                            ),
                                                        message: translate(
                                                            'memo.subscription.contact.voice.errorDuplicate'
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    }}
                                    type="tel"
                                    placeholder={translate(
                                        'memo.subscription.contact.voice.placeholder'
                                    )}
                                />
                            </FormItem>
                        </CardIcon>
                    </div>
                )}
            </Wrapper>
        </div>
    )
}

SubscriptionFormContactMethod.propTypes = {
    form: formShape,
    lang: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    memo: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    memoSettings: PropTypes.object,
    categories: PropTypes.array.isRequired,
    formValues: PropTypes.object,
    translate: PropTypes.func,
}

export default SubscriptionFormContactMethod
