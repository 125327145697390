import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'

import { fetchProjects } from '../../actions/projectActions'
import { fetchCategories } from '../../actions/categoryActions'
import { fetchService } from '../../../hub/actions/serviceActions'

import { ProjectList } from './'

const ProjectListContainer = ({ filter, serviceData, url }) => {
    const useFetchService = useCallback(() => {
        return fetchService(serviceData.id)
    }, [serviceData.id])

    const useFetchProjects = useCallback(() => {
        return fetchProjects(filter)
    }, [filter])

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const service = useFetchItems(useFetchService)
    const projects = useFetchItems(useFetchProjects)
    const categories = useFetchItems(useFetchCategories)

    // Adding {} to avoid overwrite
    const result = _.mergeWith({}, projects, categories, service, responseCustomizer)
    useDev(logErrors(result.error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={result.isLoading}>
                {result.isLoading || !_.isNil(result.error) ? (
                    <ProjectList.Placeholder />
                ) : (
                    <ProjectList
                        filter={filter}
                        projects={projects.items}
                        categories={categories.items}
                        flashsurvey={service.items.flashsurvey}
                        url={serviceData.slug}
                    />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default ProjectListContainer
