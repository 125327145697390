import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ContentLoader from 'react-content-loader'
import { withLocalize, getActiveLanguage } from 'react-localize-redux'
import { createForm, formShape } from 'rc-form'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { openSidebar } from '../../../../actions/sidebarActions'
import { Wrapper } from '../../../../components/Wrapper'
import { CardWrapper } from '../../../../components/CardWrapper'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Button } from '../../../../components/Button'
import { Layout, LayoutItem } from '../../../../components/Layout'
import {
    Form,
    FormItem,
    FormLabel,
    FormInput,
    FormSelect,
    FormFile,
    FormAutocomplete,
} from '../../../../components/Form'
import { Card, CardHeader } from '../../../../components/Card'
import { Translation } from '../../../../components/Translation'

import { createFiles } from '../../actions/inquiry/fileActions'
import { createInquiry } from '../../actions/inquiryActions'

import { searchAddresses, findServiceByType, translation } from '../../../../utils'
import { SERVICE_IDEA_IDEAS_SUBMIT } from '../../../../modules/idea/config'

const InquiryForm = ({
    isAuthenticated,
    categories,
    hubSettings: { hasRemoteAddresses },
    contactSettings: { hasInquiryPhone, hasInquiryAddress },
    fileSettings,
    lang,
    translate,
    form,
    form: { getFieldError, getFieldValue, validateFields, setFieldError },
    user,
    openSidebar,
    ideasSubmitUrl,
    faqUrl,
}) => {
    const CATEGORY_FIELD = 'category'
    const FIRSTNAME_FIELD = 'firstname'
    const LASTNAME_FIELD = 'lastname'
    const EMAIL_FIELD = 'email'
    const PHONE_FIELD = 'phone'
    const ADDRESS_FIELD = 'address'
    const POSTAL_CODE_FIELD = 'postalCode'
    const SUBJECT_FIELD = 'subject'
    const MESSAGE_FIELD = 'message'

    const [postalCode, setPostalCode] = useState(isAuthenticated ? user.postalCode : '')

    const [displayProceedConnection, setDisplayProceedConnection] = useState(false)
    const [files, setFiles] = useState([])
    const [errorFiles, setErrorFiles] = useState([])
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        setPostalCode(isAuthenticated ? user.postalCode : '')

        if (isAuthenticated) {
            setDisplayProceedConnection(false)
            setPostalCode(user.postalCode)
            // in case of logOut user with selected option
        } else if (getFieldValue(CATEGORY_FIELD)) {
            const { allowAnonymous } = _.find(
                categories,
                category => category.id === getFieldValue(CATEGORY_FIELD)
            )
            setDisplayProceedConnection(!allowAnonymous)
        }
    }, [isAuthenticated, categories, getFieldValue, user])

    const checkValidAddress = async value => {
        if (_.isNil(value)) {
            return new Promise((resolve, reject) => reject(false))
        }

        let valid = false
        await searchAddresses(value)
            .then(data => {
                const currentAddress = _.find(data, address => address.value === value)
                if (_.isNil(currentAddress)) {
                    valid = false
                    setPostalCode('')
                } else {
                    valid = true
                    if (currentAddress.postalCode === 'N/A') {
                        setPostalCode('')
                    } else {
                        setPostalCode(currentAddress.postalCode)
                    }
                }
                return null
            })
            .catch(err => [])

        return new Promise((resolve, reject) => {
            if (valid) resolve()
            else reject(false)
        })
    }

    const addressFormInputs = hasRemoteAddresses ? (
        <Fragment>
            <FormItem>
                <FormLabel inputId={ADDRESS_FIELD}>
                    <Translation value="hub.subscription.address.title" />*
                </FormLabel>
                <FormAutocomplete
                    status={[getFieldError(ADDRESS_FIELD) && 'error']}
                    id={ADDRESS_FIELD}
                    name={ADDRESS_FIELD}
                    form={form}
                    options={{
                        initialValue: isAuthenticated ? user.address : '',
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        message: translate('hub.subscription.errors.required'),
                                    },
                                    {
                                        asyncValidator: (rule, value) => checkValidAddress(value),
                                        message: translate('hub.subscription.address.invalid'),
                                    },
                                ],
                            },
                        ],
                    }}
                    // autoComplete="street-address"
                    asyncData={event => searchAddresses(event.target.value)}
                />
            </FormItem>

            <FormItem>
                <FormInput
                    form={form}
                    id={POSTAL_CODE_FIELD}
                    name={POSTAL_CODE_FIELD}
                    type="hidden"
                    options={{
                        initialValue: postalCode,
                    }}
                />
            </FormItem>
        </Fragment>
    ) : (
        <Fragment>
            <FormItem>
                <FormLabel inputId={ADDRESS_FIELD}>
                    <Translation value="hub.subscription.address.title" />*
                </FormLabel>
                <FormInput
                    status={[getFieldError(ADDRESS_FIELD) && 'error']}
                    form={form}
                    id={ADDRESS_FIELD}
                    name={ADDRESS_FIELD}
                    options={{
                        initialValue: isAuthenticated ? user.address : '',
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        message: translate('hub.subscription.errors.required'),
                                    },
                                ],
                            },
                        ],
                    }}
                    type="text"
                    placeholder={translate('hub.subscription.address.placeholder')}
                />
            </FormItem>

            <FormItem>
                <FormLabel inputId={POSTAL_CODE_FIELD}>
                    <Translation value="hub.subscription.postalCode.title" />*
                </FormLabel>
                <FormInput
                    status={[getFieldError(POSTAL_CODE_FIELD) && 'error']}
                    form={form}
                    id={POSTAL_CODE_FIELD}
                    name={POSTAL_CODE_FIELD}
                    options={{
                        initialValue: isAuthenticated ? user.postalCode : '',
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        pattern: /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/,
                                        message: translate(
                                            'hub.subscription.postalCode.errorValid'
                                        ),
                                    },
                                ],
                            },
                        ],
                    }}
                    type="text"
                    placeholder={translate('hub.subscription.postalCode.placeholder')}
                />
            </FormItem>
        </Fragment>
    )

    const checkSelectedCategory = categoryId => {
        if (!isAuthenticated) {
            const { allowAnonymous } = _.find(categories, category => category.id === categoryId)
            setDisplayProceedConnection(!allowAnonymous)
        }

        return new Promise((resolve, reject) => {
            resolve()
        })
    }

    let categoriesInputs = categories.map(category => ({
        value: category.id,
        name: category.name[lang],
    }))
    categoriesInputs = [
        { value: '', name: translate('form.dropdown.default') },
        ...categoriesInputs,
    ]

    // handle form submit
    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, formValues) => {
            if (formErrors || !_.isEmpty(errorFiles)) {
                window.scrollTo({ top: 0, behavior: 'smooth' })

                if (!_.isEmpty(errorFiles)) {
                    formErrors.files = errorFiles
                }
                return false
            }

            if (isAuthenticated) {
                formValues.user = user.id
            }

            if (files.length) {
                const uploadedFiles = await handleFileUploads().catch(error => {
                    const errorMessage = error.response.data.map(err => {
                        return new Error(err.message)
                    })
                    formErrors.files = errorMessage
                    return false
                })
                if (!uploadedFiles) return false
                formValues.files = uploadedFiles.data
            }

            formValues.lang = lang

            await createInquiry(formValues)

            setIsValid(true)
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }

    const handleFileUploads = async () => {
        let formData = new FormData()
        files.forEach((file, index) => {
            formData.append(`files[${index}]`, file)
        })

        const extraHeader = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        return await createFiles(formData, extraHeader)
    }

    return (
        <TransitionGroup>
            <CSSTransition key={isValid} classNames="has-transition" timeout={300} appear>
                <CardWrapper>
                    {isValid ? (
                        <CardFeedback
                            heading="contact.success.title"
                            text="contact.success.description"
                        >
                            <Button option={['blue', 'iconLeft']} icon="arrow-left" route="home">
                                <Translation value="contact.success.button" />
                            </Button>
                        </CardFeedback>
                    ) : (
                        <Card option={['paddingLarge']}>
                            <Wrapper option={['small', 'noPaddingSmall']}>
                                <CardHeader
                                    heading="contact.form.title"
                                    description="contact.form.description"
                                >
                                    <Layout option={['gutterTiny', 'gutterBottom']}>
                                        <LayoutItem option={['auto']}>
                                            <Button
                                                option={['button', 'small', 'gray']}
                                                href={translation(faqUrl.slug)}
                                            >
                                                <Translation value="contact.form.button1" />
                                            </Button>
                                        </LayoutItem>
                                        <LayoutItem option={['auto']}>
                                            <Button
                                                option={['button', 'small', 'gray']}
                                                href={translation(ideasSubmitUrl.slug)}
                                            >
                                                <Translation value="contact.form.button2" />
                                            </Button>
                                        </LayoutItem>
                                    </Layout>
                                </CardHeader>
                                <Form onSubmit={handleSubmit}>
                                    <FormItem>
                                        <FormLabel inputId={CATEGORY_FIELD}>
                                            <Translation value="contact.form.object.title" />*
                                        </FormLabel>
                                        <FormSelect
                                            status={[getFieldError(CATEGORY_FIELD) && 'error']}
                                            form={form}
                                            id={CATEGORY_FIELD}
                                            name={CATEGORY_FIELD}
                                            options={{
                                                initialValue: '',
                                                validateFirst: true,
                                                validate: [
                                                    {
                                                        trigger: 'onChange',
                                                        rules: [
                                                            {
                                                                required: true,
                                                            },
                                                            {
                                                                asyncValidator: (rule, value) =>
                                                                    checkSelectedCategory(value),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            }}
                                        >
                                            {categoriesInputs.map(categoryInput => (
                                                <option
                                                    key={categoryInput.value}
                                                    value={categoryInput.value}
                                                    name="category"
                                                >
                                                    {categoryInput.name}
                                                </option>
                                            ))}
                                        </FormSelect>
                                    </FormItem>
                                    {!displayProceedConnection ? (
                                        <div>
                                            <FormItem>
                                                <FormLabel inputId={FIRSTNAME_FIELD}>
                                                    <Translation value="contact.form.firstname.title" />
                                                    *
                                                </FormLabel>
                                                <FormInput
                                                    status={[
                                                        getFieldError(FIRSTNAME_FIELD) && 'error',
                                                    ]}
                                                    form={form}
                                                    id={FIRSTNAME_FIELD}
                                                    name={FIRSTNAME_FIELD}
                                                    options={{
                                                        initialValue: isAuthenticated
                                                            ? user.firstname
                                                            : '',
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="text"
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormLabel inputId={LASTNAME_FIELD}>
                                                    <Translation value="contact.form.lastname.title" />
                                                    *
                                                </FormLabel>
                                                <FormInput
                                                    status={[
                                                        getFieldError(LASTNAME_FIELD) && 'error',
                                                    ]}
                                                    form={form}
                                                    id={LASTNAME_FIELD}
                                                    name={LASTNAME_FIELD}
                                                    options={{
                                                        initialValue: isAuthenticated
                                                            ? user.lastname
                                                            : '',
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="text"
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormLabel inputId={EMAIL_FIELD}>
                                                    <Translation value="contact.form.email.title" />
                                                    *
                                                </FormLabel>
                                                <FormInput
                                                    status={[getFieldError(EMAIL_FIELD) && 'error']}
                                                    form={form}
                                                    id={EMAIL_FIELD}
                                                    name={EMAIL_FIELD}
                                                    options={{
                                                        initialValue: isAuthenticated
                                                            ? user.email
                                                            : '',
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="email"
                                                />
                                            </FormItem>
                                            {hasInquiryPhone && (
                                                <FormItem>
                                                    <FormLabel inputId={PHONE_FIELD}>
                                                        <Translation value="contact.form.phone.title" />
                                                        *
                                                    </FormLabel>
                                                    <FormInput
                                                        status={[
                                                            getFieldError(PHONE_FIELD) && 'error',
                                                        ]}
                                                        form={form}
                                                        id={PHONE_FIELD}
                                                        name={PHONE_FIELD}
                                                        options={{
                                                            initialValue: '',
                                                            validate: [
                                                                {
                                                                    trigger: 'onBlur',
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        }}
                                                        type="tel"
                                                    />
                                                </FormItem>
                                            )}

                                            {hasInquiryAddress && addressFormInputs}

                                            <FormItem>
                                                <FormLabel inputId={SUBJECT_FIELD}>
                                                    <Translation value="contact.form.subject.title" />
                                                    *
                                                </FormLabel>
                                                <FormInput
                                                    status={[
                                                        getFieldError(SUBJECT_FIELD) && 'error',
                                                    ]}
                                                    form={form}
                                                    id={SUBJECT_FIELD}
                                                    name={SUBJECT_FIELD}
                                                    options={{
                                                        initialValue: '',
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="text"
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormLabel inputId={MESSAGE_FIELD}>
                                                    <Translation value="contact.form.message.title" />
                                                    *
                                                </FormLabel>
                                                <FormInput
                                                    status={[
                                                        getFieldError(MESSAGE_FIELD) && 'error',
                                                    ]}
                                                    form={form}
                                                    id={MESSAGE_FIELD}
                                                    name={MESSAGE_FIELD}
                                                    maxLength="false"
                                                    options={{
                                                        initialValue: '',
                                                        validate: [
                                                            {
                                                                trigger: 'onBlur',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    type="textarea"
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormLabel inputId={'files'}>
                                                    <Translation value="contact.form.files.title" />
                                                </FormLabel>
                                                <FormFile
                                                    id={'files'}
                                                    name={'files'}
                                                    multiple={true}
                                                    acceptedMimetypes={
                                                        fileSettings.accepted_mimetypes
                                                    }
                                                    maxFilesize={fileSettings.max_filesize}
                                                    setFiles={files => setFiles(files)}
                                                    setErrors={errors => setErrorFiles(errors)}
                                                />
                                                <ul>
                                                    {errorFiles.map(({ type, value }, i) => (
                                                        <li key={i}>
                                                            <Translation value={type} />{' '}
                                                            {value && `: ${value}`}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </FormItem>
                                            <FormItem>
                                                <Button
                                                    option={['green', 'full', 'large', 'iconRight']}
                                                    icon="arrow-right"
                                                    type="submit"
                                                >
                                                    <Translation value="contact.form.submit.title" />
                                                </Button>
                                            </FormItem>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <FormLabel utility={['red']}>
                                                <Translation value="contact.form.connect.error" />
                                            </FormLabel>
                                            <Button onClick={() => openSidebar()}>
                                                <Translation value="hub.loginForm.login" />
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Form>
                            </Wrapper>
                        </Card>
                    )}
                </CardWrapper>
            </CSSTransition>
        </TransitionGroup>
    )
}

InquiryForm.propTypes = {
    categories: PropTypes.array.isRequired,
    hubSettings: PropTypes.object.isRequired,
    contactSettings: PropTypes.object.isRequired,
    fileSettings: PropTypes.object.isRequired,
    form: formShape,
    openSidebar: PropTypes.func.isRequired,
}

InquiryForm.Placeholder = () => <ContentLoader></ContentLoader>

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    ideasSubmitUrl: findServiceByType(state.hub.services.entities, SERVICE_IDEA_IDEAS_SUBMIT),
    faqUrl: findServiceByType(state.hub.services.entities, 'city/content/service/questions'),
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(createForm()(InquiryForm)))
