import React from 'react'

import PropTypes from 'prop-types'
import { renderClasses, isTranslationObject, formatDate } from '../../utils'

import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { Button } from '../Button'
import { Translation } from '../Translation'

import './_headerPage.scss'
import { SectionHeader } from '../Section'
import { Wrapper } from '../Wrapper'
import { Card, CardHeader, CardLabel } from '../Card'

const HeaderPage = ({
    heading,
    subheading,
    breadcrumb,
    icon,
    introBox = {},
    featured = false,
    backLink = false,
    ...classProps
}) => (
    <React.Fragment>
        <header className={renderClasses('headerPage' + (featured ? ' -featured' : ''), classProps)}>
            {backLink && (
                <div className={'headerPage_button'}>
                    <Button option={['circle', 'white']} href={backLink} icon="arrow-left" />
                </div>
            )}

            {breadcrumb && (
                <p className="headerPage_breadcrumb">
                    <Translation value={breadcrumb} />
                </p>
            )}

            <div className="headerPage_heading">
                <Heading el="h1" option={['h1', 'noMargin']}>
                    <Translation value={heading} />
                </Heading>
            </div>

            {subheading && <p className="headerPage_subheading">{subheading}</p>}

            {icon && (
                <div className="headerPage_icon">
                    <Icon name={icon} />
                </div>
            )}

        </header>
        {introBox && introBox.active && (
            <SectionHeader>
                <Wrapper>
                    <Card>
                        {introBox.title && <CardHeader option={['small']} heading={introBox.title}/>}
                        {introBox.description && <Translation value={introBox.description}/>}
                    </Card>
                </Wrapper>
            </SectionHeader>
        )}
    </React.Fragment>
)

HeaderPage.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.string, isTranslationObject]).isRequired,
    subheading: PropTypes.oneOfType([PropTypes.object, PropTypes.string, isTranslationObject]),
    breadcrumb: PropTypes.oneOfType([PropTypes.string, isTranslationObject]),
    icon: PropTypes.string,
    introBox: PropTypes.object,
    featured: PropTypes.bool,
    backLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, isTranslationObject]),
}

export default HeaderPage
