import React from 'react'
import _ from 'lodash'

import { ButtonCheckbox } from '../../../../components/Button'
import { FormItem } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Wrapper } from '../../../../components/Wrapper'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { translation } from '../../../../utils'

const QuestionChoiceCheckbox = ({
    question: { id, choices, required },
    form,
    form: { getFieldValue, getFieldError, setFieldsInitialValue, isFieldTouched },
    formValues = {},
}) => {
    let isImages = false
    choices.map(choice => {
        if (choice.image) {
            isImages = true
        }
        return null
    })

    const getChoice = hasImage =>
        choices.map(choice => {
            let result = (
                <FormItem option={['medium', 'anim']} key={choice.id}>
                    <ButtonCheckbox
                        id={choice.id}
                        form={form}
                        status={[getFieldError(id) && 'error', choice.image ? 'image' : '']}
                        value={choice.id}
                        option={['full']}
                        checked={
                            formValues[id] && formValues[id].indexOf(choice.id) !== -1
                                ? true
                                : false
                        }
                        name={`${id}`}
                        options={{
                            initialValue:
                                formValues[id] && !_.isEmpty(formValues[id]) ? formValues[id] : '',
                            preserve: getFieldValue(id) ? true : false,
                            validate: [
                                {
                                    trigger: 'onChange',
                                    rules: [
                                        {
                                            required: required,
                                        },
                                    ],
                                },
                            ],
                            getValueFromEvent: e => {
                                let questionValue = getFieldValue(id)
                                if (!questionValue) {
                                    return [e.target.value]
                                }

                                if (questionValue.indexOf(e.target.value) === -1) {
                                    return [...questionValue, e.target.value]
                                }
                                return _.filter(questionValue, value => value !== e.target.value)
                            },
                        }}
                    >
                        {choice.image && <img src={translation(choice.image)} alt="" />}
                        <Translation value={choice.content} isHtml />
                    </ButtonCheckbox>
                </FormItem>
            )

            if (hasImage) {
                result = (
                    <LayoutItem utility={['1/2']} key={choice.id}>
                        {result}
                    </LayoutItem>
                )
            }
            return result
        })

    return isImages ? (
        <Wrapper>
            <Layout option={['gutterSmall']}>{getChoice('image')}</Layout>
        </Wrapper>
    ) : (
        <Wrapper option={['small']}>{getChoice()}</Wrapper>
    )
}

export default QuestionChoiceCheckbox
