import React from 'react'

import { View } from '../../../components/View'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { HeaderPage } from '../../../components/HeaderPage'

import { AlertListContainer } from '../components/Alert'

const AlertListView = ({ serviceData, serviceCategoryData }) => (
    <View>
        <ServiceMetatags data={serviceData} />
        <HeaderPage
            heading="memo.alerts.listing.title"
            breadcrumb={serviceCategoryData.name}
            icon={serviceData.icon}
            introBox={serviceData.introBox}
        />
        <AlertListContainer />
    </View>
)

export default AlertListView
