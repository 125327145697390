import React from 'react'
import PropTypes from 'prop-types'

import { Layout, LayoutItem } from '../../../components/Layout'

import { Translation } from '../../Translation'
import { isTranslationObject } from '../../../utils'

const ContentBlockTableRow = ({ important = false, title, value }) => {
    return (
        <li className={'contentTable_row' + (important ? ' -bold' : '')}>
            <Layout option={['gutter']}>
                <LayoutItem utility={['1/2@from-medium']}>
                    <span className="contentTable_row_content">
                        <Translation value={title} />
                    </span>
                </LayoutItem>

                <LayoutItem utility={['1/2@from-medium']}>
                    <span className="contentTable_row_content">
                        <Translation value={value} />
                    </span>
                </LayoutItem>
            </Layout>

            {important}
        </li>
    )
}

ContentBlockTableRow.propTypes = {
    title: isTranslationObject,
    value: isTranslationObject,
    important: PropTypes.bool,
}

export default ContentBlockTableRow
