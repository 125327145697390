import React, { useState, useEffect, useCallback, useRef } from 'react'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { createForm, formShape } from 'rc-form'
import _ from 'lodash'
import axios from 'axios'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Card } from '../../../../components/Card'
import { CardWrapper } from '../../../../components/CardWrapper'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Button } from '../../../../components/Button'
import { HeaderPage } from '../../../../components/HeaderPage'
import { Heading } from '../../../../components/Heading'
import { Wrapper } from '../../../../components/Wrapper'
import { ProgressBar } from '../../../../components/ProgressBar'
import { Form, FormItem } from '../../../../components/Form'
import { Translation } from '../../../../components/Translation'
import { Spinner } from '../../../../components/Spinner'
import { Link } from '../../../../components/Link'

import {
    LOCALSTORAGE_SUBSCRIBED_USERID,
    LOCALSTORAGE_SUBSCRIBED_USER_EMAIL,
} from '../../../hub/config'

import { createSubscription } from '../../actions/subscriptionActions'

import './_subscriptionForm.scss'

import {
    SubscriptionFormContactMethod,
    SubscriptionFormCategories,
    SubscriptionFormZoneList,
} from '.'

const SubscriptionForm = props => {
    const {
        memoSettings: {
            general: { categoryParking, categorySnow, categoryWaste },
        },
        lang,
        memo,
        hasMemoSubscription,
        form: { validateFields },
        isAuthenticated,
        categories,
        // user,
        userId,
        updateSelfSubscription,
        fetchSelfSubscription,
        fetchSelf,
    } = props

    const defaultSteps = useRef([
        {
            title: 'memo.subscription.contact.title',
            Component: SubscriptionFormContactMethod,
        },
        {
            title: 'memo.subscription.categories.title',
            Component: SubscriptionFormCategories,
        },
    ])

    const fullSteps = useRef([
        ...defaultSteps.current,
        {
            title: 'memo.subscription.zones.title',
            Component: SubscriptionFormZoneList,
        },
    ])

    const [step, setStep] = useState(0)
    const [formValues, setFormValues] = useState({})
    const [isFinished, setIsFinished] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const hasSelectedGoogleMapCategories = useCallback(() => {
        if (_.isEmpty(categoryParking) && _.isEmpty(categorySnow) && _.isEmpty(categoryWaste)) {
            return false
        }

        const gMapCategories = _.filter(categories, category =>
            [categoryParking, categorySnow, categoryWaste].includes(category.id)
        )

        for (var i = 0; i < gMapCategories.length; i++) {
            if (
                gMapCategories[i].enabledByDefault ||
                (isAuthenticated &&
                    _.isEmpty(formValues.categories) &&
                    _.indexOf(memo.categories, categories[i].id) !== -1) ||
                (formValues && _.indexOf(formValues.categories, gMapCategories[i].id) !== -1)
            ) {
                return true
            }
        }

        return false
    }, [
        categories,
        categoryParking,
        categorySnow,
        categoryWaste,
        isAuthenticated,
        memo.categories,
        formValues,
    ])

    const retrieveDisplayZone = isDisplayed => {
        setSteps(!isDisplayed ? defaultSteps.current : fullSteps.current)
    }

    const [steps, setSteps] = useState(
        !hasSelectedGoogleMapCategories() ? defaultSteps.current : fullSteps.current
    )

    useEffect(() => {
        setSteps(!hasSelectedGoogleMapCategories() ? defaultSteps.current : fullSteps.current)
    }, [isAuthenticated, hasSelectedGoogleMapCategories, step])

    const signal = axios.CancelToken.source()

    const handleSubmit = event => {
        event.preventDefault()

        validateFields(async (formErrors, currentFormValues) => {
            if (formErrors) {
                return false
            }
            setIsLoading(true)

            let subscriptionFields = { ...formValues, ...currentFormValues }
            subscriptionFields.lang = lang
            subscriptionFields.user = userId
            if (!hasMemoSubscription) {
                await createSubscription(subscriptionFields, { cancelToken: signal.token })
                    .then(res => res.data)
                    .catch(err => null)

                // remove data for MemoSubscription
                localStorage.removeItem(LOCALSTORAGE_SUBSCRIBED_USERID)
                localStorage.removeItem(LOCALSTORAGE_SUBSCRIBED_USER_EMAIL)
            } else {
                subscriptionFields.id = memo.id
                await updateSelfSubscription(subscriptionFields)
                // retrieve new user infos
                fetchSelfSubscription()
            }

            // refresh memoSubscription data from user
            if (isAuthenticated) {
                fetchSelf()
            }

            setIsLoading(false)
            setIsFinished(true)
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }

    const prevStep = event => {
        event.preventDefault()
        setStep(step - 1)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const nextStep = event => {
        event.preventDefault()

        validateFields(async (formErrors, currentFormValues) => {
            if (formErrors) {
                return false
            }

            setFormValues({ ...formValues, ...currentFormValues })
            setStep(step + 1)
        })

        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const headerPage = (
        <HeaderPage
            option={['noMargin']}
            heading="memo.profile.modify"
            breadcrumb="app.espace_citoyen.title"
            icon="subscribe"
        />
    )

    const progressBar = (
        <ProgressBar option={['margin']} step={step} data={_.flatMap(steps, step => step.title)} />
    )

    const FormWizard = steps[step].Component

    return (
        <div>
            {headerPage}
            {progressBar}

            <Wrapper>
                <TransitionGroup>
                    <CSSTransition
                        key={isFinished}
                        classNames="has-transition"
                        timeout={300}
                        appear
                    >
                        <CardWrapper>
                            {isLoading ? (
                                <Card option={['paddingLarge']}>
                                    <Spinner />
                                </Card>
                            ) : isFinished && !isAuthenticated ? (
                                <CardFeedback heading="memo.subscription.success.title">
                                    <Button
                                        option={['blue', 'iconLeft']}
                                        icon="arrow-left"
                                        route="home"
                                    >
                                        <Translation value="memo.subscription.success.button" />
                                    </Button>
                                </CardFeedback>
                            ) : isFinished && isAuthenticated ? (
                                <CardFeedback heading="memo.subscription.update.title">
                                    <Button
                                        option={['blue', 'iconLeft']}
                                        icon="arrow-left"
                                        route="home"
                                    >
                                        <Translation value="memo.subscription.update.button" />
                                    </Button>
                                </CardFeedback>
                            ) : (
                                <>
                                    {step === 0 && !isAuthenticated && (
                                        <Card
                                            option={['green', 'marginSmall']}
                                            utility={['textCenter']}
                                        >
                                            <Wrapper>
                                                <Heading option={['h2']}>
                                                    <Translation value="memo.subscription.received.title" />
                                                </Heading>
                                                <p>
                                                    <Translation value="memo.subscription.received.description" />
                                                </p>
                                            </Wrapper>
                                        </Card>
                                    )}
                                    <Card option={['paddingLarge']}>
                                        <Form onSubmit={handleSubmit}>
                                            <TransitionGroup>
                                                <CSSTransition
                                                    key={FormWizard}
                                                    classNames="has-transition"
                                                    timeout={300}
                                                    appear
                                                >
                                                    <CardWrapper>
                                                        <FormWizard
                                                            {...props}
                                                            formValues={formValues}
                                                            displayZoneList={isDisplayed =>
                                                                retrieveDisplayZone(isDisplayed)
                                                            }
                                                        />
                                                    </CardWrapper>
                                                </CSSTransition>
                                            </TransitionGroup>

                                            <Wrapper option={['small', 'noPaddingSmall']}>
                                                <div className="subscriptionForm_buttons">
                                                    {step !== steps.length - 1 && !isFinished && (
                                                        <React.Fragment>
                                                            <FormItem>
                                                                <Button
                                                                    option={[
                                                                        'green',
                                                                        'full',
                                                                        'large',
                                                                        'iconRight',
                                                                    ]}
                                                                    icon="arrow-right"
                                                                    type="submit"
                                                                    onClick={nextStep}
                                                                >
                                                                    <Translation value="memo.subscription.next" />
                                                                </Button>
                                                            </FormItem>

                                                            {step === 0 &&
                                                                isAuthenticated &&
                                                                hasMemoSubscription && (
                                                                    <div className="u-textCenter">
                                                                        <Link
                                                                            option={[
                                                                                'red',
                                                                                'underline',
                                                                            ]}
                                                                            route={
                                                                                'memo-unsubscription'
                                                                            }
                                                                        >
                                                                            <Translation value="memo.unsubscription.label" />
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                        </React.Fragment>
                                                    )}

                                                    {step === steps.length - 1 && !isFinished && (
                                                        <FormItem option={['medium']}>
                                                            <Button
                                                                option={[
                                                                    'green',
                                                                    'full',
                                                                    'large',
                                                                    'iconRight',
                                                                ]}
                                                                icon="arrow-right"
                                                                type="submit"
                                                                onClick={handleSubmit}
                                                            >
                                                                <Translation value="memo.subscription.submit" />
                                                            </Button>
                                                        </FormItem>
                                                    )}

                                                    {step !== 0 && !isFinished && (
                                                        <FormItem utility={['textCenter']}>
                                                            <Button
                                                                option={[
                                                                    'small',
                                                                    'gray',
                                                                    'iconLeft',
                                                                ]}
                                                                icon="arrow-left"
                                                                onClick={prevStep}
                                                            >
                                                                <Translation value="memo.subscription.previous" />
                                                            </Button>
                                                        </FormItem>
                                                    )}
                                                </div>
                                            </Wrapper>
                                        </Form>
                                    </Card>
                                </>
                            )}
                        </CardWrapper>
                    </CSSTransition>
                </TransitionGroup>
            </Wrapper>
        </div>
    )
}

SubscriptionForm.propTypes = {
    form: formShape,
    lang: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    memo: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    userId: PropTypes.string,
    updateSelfSubscription: PropTypes.func,
    fetchSelfSubscription: PropTypes.func,
}

SubscriptionForm.Placeholder = () => <ContentLoader></ContentLoader>

export default withLocalize(createForm()(SubscriptionForm))
