import { connect } from 'react-redux'

import { logout } from '../../actions/userActions'

import { Profile } from '../Profile'

const mapStateToProps = state => ({
    isHidden: state.hub.auth.user === null,
    user: state.hub.auth.user,
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
})

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile)

export default ProfileContainer
