import React from 'react'

import { HeaderPage } from '../../../components/HeaderPage'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { View } from '../../../components/View'
import { translation } from '../../../utils'

import { IdeaSubmitFormContainer } from '../components/Idea'

const IdeaSubmitView = ({ serviceData, serviceCategoryData }) => (
    <View>
        <ServiceMetatags data={serviceData} />
        <HeaderPage
            heading="idea.listing.title"
            breadcrumb={serviceCategoryData.name}
            icon={serviceData.icon}
            introBox={serviceData.introBox}
            backLink={false && serviceData && translation(serviceData.slug)}
        />

        <IdeaSubmitFormContainer serviceData={serviceData} />
    </View>
)

export default IdeaSubmitView
