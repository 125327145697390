import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getActiveLanguage } from 'react-localize-redux'

import { fetchSelfSubscription } from '../../../memo/actions/subscriptionActions'

import { login, updateSelf, fetchSelf } from '../../actions/userActions'

import { SubscriptionForm } from '.'

const SubscriptionFormContainer = props => {
    const { hasMemoSubscription, fetchSelfSubscription } = props

    useEffect(() => {
        if (!hasMemoSubscription) {
            return () => false
        }
        fetchSelfSubscription()
    }, [hasMemoSubscription, fetchSelfSubscription])

    return <SubscriptionForm {...props} />
}

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    hasRemoteAddresses: state.hub.hubSettings.entities.hasRemoteAddresses,
    hasMemoSubscription:
        state.hub.auth.isAuthenticated && state.hub.auth.user.memoSubscription ? true : false,
    privacyPolicy: state.hub.hubSettings.entities.privacyPolicy,
    termsOfUse: state.hub.hubSettings.entities.termsOfUse,
})

const mapDispatchToProps = dispatch => ({
    login: (data, config) => dispatch(login(data, config)),
    updateSelf: (data, config) => dispatch(updateSelf(data, config)),
    fetchSelf: config => dispatch(fetchSelf(config)),
    fetchSelfSubscription: config => dispatch(fetchSelfSubscription(config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionFormContainer)
