import React from 'react'

import { View } from '../../../components/View'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { HeaderPage } from '../../../components/HeaderPage'
import { Wrapper } from '../../../components/Wrapper'

import { InquiryFormContainer } from '../components/Inquiry'

const InquiryView = ({ serviceData, serviceCategoryData }) => (
    <View>
        <ServiceMetatags data={serviceData} />
        <HeaderPage
            heading={serviceData.name}
            breadcrumb={serviceCategoryData.name}
            icon={serviceData.icon}
            introBox={serviceData.introBox}
        />
        <Wrapper>
            <InquiryFormContainer />
        </Wrapper>
    </View>
)

export default InquiryView
