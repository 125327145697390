import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import _ from 'lodash'

import { Wrapper } from '../../../../components/Wrapper'
import { Card, CardHeader } from '../../../../components/Card'
import { FormInput, FormItem, FormError } from '../../../../components/Form'

import { SubscriptionFormCollapse } from '.'

const SubscriptionFormCategories = ({
    memoSettings: {
        general: { categoryParking, categorySnow, categoryWaste },
    },
    displayZoneList,
    isAuthenticated,
    translate,
    form,
    form: { getFieldError, setFieldsValue, isFieldTouched },
    categories,
    memo,
    formValues,
}) => {
    let categoriesValues = []

    for (var i = 0; i < categories.length; i++) {
        const category = categories[i]

        categoriesValues[category.id] =
            // select current formValues if set
            _.indexOf(formValues.categories, category.id) !== -1
                ? true
                : // select memo data from user if no formValues
                isAuthenticated &&
                  _.isEmpty(formValues.categories) &&
                  _.indexOf(memo.categories, category.id) !== -1
                ? true
                : _.isUndefined(memo.categories)
                ? category.enabledByDefault
                : false
    }

    const [inputCategoriesValues, setInputCategoriesValues] = useState(categoriesValues)

    const handleOnChange = categoryId => {
        inputCategoriesValues[categoryId] = !inputCategoriesValues[categoryId]
        setInputCategoriesValues({ ...inputCategoriesValues })

        setFieldsValue({ categories: _.keys(_.pickBy(inputCategoriesValues)) })

        // handle changes to display/hide GoogleMap step.
        let hasGoogleMapCategories = false
        _.keys(_.pickBy(inputCategoriesValues)).map(category => {
            if (
                (!_.isEmpty(categoryParking) && category === categoryParking) ||
                (!_.isEmpty(categorySnow) && category === categorySnow) ||
                (!_.isEmpty(categoryWaste) && category === categoryWaste)
            ) {
                hasGoogleMapCategories = true
            }
            return null
        })
        displayZoneList(hasGoogleMapCategories)
    }

    const inputValue = _.keys(_.pickBy(inputCategoriesValues))

    return (
        <div>
            <Wrapper option={['small', 'noPaddingSmall']}>
                <CardHeader
                    heading="memo.subscription.categories.title"
                    description="memo.subscription.categories.description"
                />
            </Wrapper>
            <Wrapper option={['large', 'noPaddingSmall']}>
                <FormItem>
                    <FormInput
                        form={form}
                        id="categories"
                        name="categories"
                        value={inputValue}
                        options={{
                            initialValue: inputValue,
                            validateFirst: true,
                            rules: [
                                {
                                    required: true,
                                    message: translate(
                                        'memo.subscription.categories.errors.required'
                                    ),
                                },
                            ],
                        }}
                        type="hidden"
                    />
                </FormItem>
                <FormError>{getFieldError('categories')}</FormError>

                <Card option={['noPadding']}>
                    <CardHeader
                        option={['small']}
                        heading="memo.subscription.categories.subtitle"
                    />

                    {categories.map((item, i) => {
                        item.checked = categoriesValues[item.id]
                        return (
                            <SubscriptionFormCollapse
                                item={item}
                                key={i}
                                onCheck={id => handleOnChange(id)}
                            />
                        )
                    })}
                </Card>
            </Wrapper>
        </div>
    )
}

SubscriptionFormCategories.propTypes = {
    memoSettings: PropTypes.object,
    form: formShape,
    lang: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    memo: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    categories: PropTypes.array.isRequired,
    formValues: PropTypes.object,
}

export default SubscriptionFormCategories
