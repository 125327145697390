import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../components/Translation'
import { Link } from '../../../../components/Link'
import { isTranslationObject, localizePath, formatDate } from '../../../../utils'

import { AlertCategory } from '.'
import { Heading } from '../../../../components/Heading'

import './_alertTableRow.scss'

const AlertTableRow = ({ title, slug, featured, category, startDate }) => (
    <article className="alertTableRow">
        <Link className="alertTableRow_link" href={localizePath(slug)}>
            {category && <AlertCategory category={category} featured={featured} />}

            <div className="alertTableRow_content">
                <div className="alertTableRow_title">
                    <Heading option={['h6', 'noMargin']} el="h3">
                        <Translation value={title} />
                    </Heading>
                </div>

                <div className={'alertTableRow_date' + (featured ? ' -featured' : '')}>
                    <span>
                        <Translation value={category.name} />
                    </span>

                    <span>{formatDate(startDate)}</span>
                </div>
            </div>
        </Link>
    </article>
)

AlertTableRow.propTypes = {
    title: isTranslationObject,
    featured: PropTypes.bool,
    slug: isTranslationObject,
    category: PropTypes.shape({
        name: isTranslationObject,
        color: PropTypes.string,
        icon: PropTypes.string,
    }),
}

export default AlertTableRow
