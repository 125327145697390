import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'

import { Button } from '../../../../components/Button'

import { createVote, updateVote } from '../../actions/voteActions'

import './_ideaVote.scss'

const IdeaVote = ({
    idea,
    createVote,
    updateVote,
    isAuthenticated,
    votes,
    user,
    isDetails = false,
}) => {
    const currentVote = _.find(votes, vote => vote.idea === idea.id) || false
    const [voteWeight, setVoteWeight] = useState(currentVote.weight || false)

    // change on user connexion
    useEffect(() => {
        setVoteWeight(currentVote.weight)
        return () => {
            setVoteWeight(false)
        }
    }, [currentVote])

    const signal = axios.CancelToken.source()

    const vote = (e, weight) => {
        e.preventDefault()

        // do not vote for already set vote
        if (weight === voteWeight) {
            return false
        }

        let voteData = {
            idea: idea.id,
            user: isAuthenticated ? user.id : null,
            weight: weight,
        }

        if (currentVote) {
            voteData.id = currentVote.id
            updateVote(voteData, { cancelToken: signal.token })
        } else {
            createVote(voteData, { cancelToken: signal.token })
        }
        setVoteWeight(weight)
    }

    return (
        <div className={'ideaVote' + (isDetails ? ' -large' : ' -small')}>
            <div className='ideaVote_button-wrapper'>
                <span className='ideaVote_button-wrapper_count'>
                    {idea.numPositiveVotes}
                </span>

                <Button
                    option={[
                        'circle',
                        'icon',
                        voteWeight === 1 ? 'green' : 'white',
                        isDetails && 'large',
                    ]}
                    onClick={e => vote(e, 1)}
                    icon="thumb-up"
                    className="ideaVote_button"
                />
            </div>

            <div className='ideaVote_button-wrapper'>
                <span className='ideaVote_button-wrapper_count'>
                    {-idea.numNegativeVotes}
                </span>

                <Button
                    option={[
                        'circle',
                        'icon',
                        voteWeight === -1 ? 'red' : 'white',
                        isDetails && 'large',
                    ]}
                    onClick={e => vote(e, -1)}
                    icon="thumb-down"
                    className="ideaVote_button"
                />
            </div>
        </div>
    )
}

IdeaVote.propTypes = {
    idea: PropTypes.object.isRequired,
    createVote: PropTypes.func.isRequired,
    updateVote: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    votes: PropTypes.array,
    isDetails: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    votes: state.idea.votes.entities || [],
})

const mapDispatchToProps = dispatch => ({
    createVote: (data, config) => dispatch(createVote(data, config)),
    updateVote: (data, config) => dispatch(updateVote(data, config)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdeaVote)
