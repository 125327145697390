import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { connect } from 'react-redux'
import { openLightbox } from '../../../../actions/lightboxActions'

import { Button } from '../../../../components/Button'
import { Wrapper } from '../../../../components/Wrapper'
import { Translation } from '../../../../components/Translation'
import { FormItem } from '../../../../components/Form'
import { Content } from '../../../../components/Content'
import { isTranslationObject, translation } from '../../../../utils'

import { QuestionChoiceManager } from '.'

import avatar from '../../../../assets/images/avatar.svg'

import './_question.scss'

const Question = ({
    question,
    nextQuestion,
    prevQuestion,
    logAnswer,
    form,
    formValues,
    isLast,
    handleOpenLightbox
}) => {
    const currentFieldValue = form.getFieldValue(question.id)
    const handleNextQuestion = () => {
        logAnswer(question.type, currentFieldValue)
        nextQuestion()
    }

    const skipToNextQuestion = () => {
        form.setFieldsValue({ [question.id]: '' })
        nextQuestion()
    }

    const [isDisabled, setIsDisabled] = useReducer(
        () => !!(question.required && (!currentFieldValue || _.isEmpty(currentFieldValue)))
    )

    const [canSkipQuestion, setCanSkipQuestion] = useState(false)

    // Reset the skip state when the question change
    useEffect(() => setCanSkipQuestion(false), [question.id])

    // Compute isDisable when field value updates
    useEffect(() => setIsDisabled(), [currentFieldValue])

    return (
        <div className="question">
            <Wrapper option={['small']}>
                <p className="question_title">
                    <Translation value="survey.details.title" />
                </p>
                <div className="question_wrap">
                    <div className="question_avatar">
                        <img src={avatar} alt="" />
                    </div>
                    <TransitionGroup>
                        <CSSTransition
                            key={question.id}
                            classNames="has-transition"
                            timeout={300}
                            appear
                        >
                            <div className="question_bubble">
                                <Translation value={question.title} />
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>

                {question.subtitle && (
                    <Content className="question_description">
                        <Translation value={question.subtitle} isHtml/>
                    </Content>
                )}

                {question.image && (
                    <div className="question_image u-relative">
                        <Button onClick={() => { handleOpenLightbox(question.image,question.image,translation(question.title)) }} option={['white', 'circle', 'icon', 'small', 'zoom']} icon="search"/>
                        <img
                            src={question.image}
                            alt=""
                        />
                    </div>
                )}
                {/*question.file*/}
            </Wrapper>
            <div className="question_wrap">
                <TransitionGroup>
                    <CSSTransition
                        key={question.id}
                        classNames="has-transition"
                        timeout={400}
                        appear
                    >
                        <div className="form_group -anim">
                            <QuestionChoiceManager
                                question={question}
                                form={form}
                                formValues={formValues}
                                allowSkip={val => {
                                    setCanSkipQuestion(val)
                                }}
                            />
                            <Wrapper option={['small']}>
                                <div className="question_buttons">
                                    <FormItem option={['medium']}>
                                        <Button
                                            option={['green', 'full', 'large', 'iconRight']}
                                            status={[isDisabled && 'disabled']}
                                            icon="arrow-right"
                                            type="submit"
                                            onClick={handleNextQuestion}
                                            disabled={isDisabled}
                                        >
                                            <Translation
                                                value={
                                                    isLast
                                                        ? 'survey.details.submit.title'
                                                        : 'survey.details.next.title'
                                                }
                                            />
                                        </Button>
                                    </FormItem>

                                    {canSkipQuestion && (
                                        <FormItem>
                                            <Button
                                                option={['white', 'small', 'iconRight']}
                                                icon="arrow-right"
                                                onClick={skipToNextQuestion}
                                            >
                                                <Translation
                                                    value={
                                                        isLast
                                                            ? 'survey.details.skip-submit.title'
                                                            : 'survey.details.skip.title'
                                                    }
                                                />
                                            </Button>
                                        </FormItem>
                                    )}

                                    <FormItem>
                                        <Button
                                            option={['small', 'gray', 'iconLeft']}
                                            icon="arrow-left"
                                            onClick={prevQuestion}
                                        >
                                            <Translation value="survey.details.previous.title" />
                                        </Button>
                                    </FormItem>
                                </div>
                            </Wrapper>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </div>
    )
}

Question.propTypes = {
    question: PropTypes.shape({
        choices: PropTypes.array.isRequired,
        content: isTranslationObject,
        image: PropTypes.string,
        file: PropTypes.string,
        view: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isLast: PropTypes.bool,
    }).isRequired,
    nextQuestion: PropTypes.func.isRequired,
    logAnswer: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
    handleOpenLightbox: (urlToMediumImageFile,urlToLargeImageFile,alt) => dispatch(openLightbox(urlToMediumImageFile,urlToLargeImageFile,alt)),
})

export default connect(null, mapDispatchToProps)(Question)
