import React from 'react'

import { HeaderPage } from '../../../components/HeaderPage'
import { ServiceMetatags } from '../../../components/ServiceMetatags'
import { View } from '../../../components/View'
import { IdeaListContainer } from '../components/Idea'

const IdeaListView = ({ location, serviceData, serviceCategoryData }) => (
    <View>
        <ServiceMetatags data={serviceData} />
        <HeaderPage
            heading="idea.listing.title"
            breadcrumb={serviceCategoryData.name}
            icon={serviceData.icon}
            introBox={serviceData.introBox}
        />

        <IdeaListContainer serviceData={serviceData} />
    </View>
)

export default IdeaListView
