import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import _ from 'lodash'

import { Translation } from '../../../../components/Translation'

import { FormInput, FormItem, FormLabel, FormAutocomplete } from '../../../../components/Form'

import { searchAddresses } from '../../../../utils'

const SubscriptionAddressForm = ({
    hasRemoteAddresses,
    form,
    form: { getFieldError, getFieldValue, validateFields },
    translate,
    isAuthenticated,
    user,
    addressField = 'address',
    postalCodeField = 'postalCode',
}) => {
    const ADDRESS_ID_FIELD = 'addressId'

    const [addressId, setAddressId] = useState(
        isAuthenticated && user.addressId ? user.addressId : ''
    )
    const [postalCode, setPostalCode] = useState(isAuthenticated ? user.postalCode : '')

    const checkValidAddress = async value => {
        if (_.isNil(value)) {
            return new Promise((resolve, reject) => reject(false))
        }

        let valid = false
        await searchAddresses(value)
            .then(data => {
                const currentAddress = _.find(data, address => address.value === value)
                if (_.isNil(currentAddress)) {
                    valid = false
                    setAddressId('')
                    setPostalCode('')
                } else {
                    valid = true
                    setAddressId(currentAddress.id)
                    if (currentAddress.postalCode && currentAddress.postalCode !== 'N/A') {
                        setPostalCode(currentAddress.postalCode)
                    }
                }
                return null
            })
            .catch(err => [])

        return new Promise((resolve, reject) => {
            if (valid) resolve()
            else reject(false)
        })
    }

    return hasRemoteAddresses ? (
        <Fragment>
            <FormItem>
                <FormLabel inputId={addressField}>
                    <Translation value="hub.subscription.address.title" />
                </FormLabel>
                <FormAutocomplete
                    status={[getFieldError(addressField) && 'error']}
                    id={addressField}
                    name={addressField}
                    form={form}
                    placeholder={translate('hub.subscription.address.placeholder')}
                    options={{
                        initialValue: isAuthenticated ? user.address : '',
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        asyncValidator: (rule, value) => checkValidAddress(value),
                                        message: translate('hub.subscription.address.invalid'),
                                    },
                                ],
                            },
                        ],
                    }}
                    // autoComplete="street-address"
                    asyncData={event => searchAddresses(event.target.value)}
                />
            </FormItem>

            <FormItem>
                <FormInput
                    form={form}
                    id={ADDRESS_ID_FIELD}
                    name={ADDRESS_ID_FIELD}
                    type="hidden"
                    options={{
                        initialValue: addressId,
                    }}
                />
            </FormItem>

            <FormItem>
                <FormInput
                    form={form}
                    id={postalCodeField}
                    name={postalCodeField}
                    type="hidden"
                    options={{
                        initialValue: postalCode,
                    }}
                />
            </FormItem>
        </Fragment>
    ) : (
        <Fragment>
            <FormItem>
                <FormLabel inputId={addressField}>
                    <Translation value="hub.subscription.address.title" />
                </FormLabel>
                <FormInput
                    status={[getFieldError(addressField) && 'error']}
                    form={form}
                    id={addressField}
                    name={addressField}
                    options={{
                        initialValue: isAuthenticated ? user.address : '',
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        message: translate('hub.subscription.errors.required'),
                                    },
                                ],
                            },
                        ],
                    }}
                    type="text"
                    placeholder={translate('hub.subscription.address.placeholder')}
                />
            </FormItem>

            <FormItem>
                <FormLabel inputId={postalCodeField}>
                    <Translation value="hub.subscription.postalCode.title" />*
                </FormLabel>
                <FormInput
                    status={[getFieldError(postalCodeField) && 'error']}
                    form={form}
                    id={postalCodeField}
                    name={postalCodeField}
                    options={{
                        initialValue: isAuthenticated ? user.postalCode : postalCode,
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        pattern: /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/,
                                        message: translate(
                                            'hub.subscription.postalCode.errorValid'
                                        ),
                                    },
                                ],
                            },
                        ],
                    }}
                    type="text"
                    placeholder={translate('hub.subscription.postalCode.placeholder')}
                />
            </FormItem>
        </Fragment>
    )
}

SubscriptionAddressForm.propTypes = {
    form: formShape,
    translate: PropTypes.func.isRequired,
    hasRemoteAddresses: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

const mapStateToProps = state => ({
    isAuthenticated: state.hub.auth.isAuthenticated,
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    hasRemoteAddresses: state.hub.hubSettings.entities.hasRemoteAddresses,
})

export default connect(mapStateToProps)(SubscriptionAddressForm)
