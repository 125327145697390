import { API_URL } from '../config'

const baseUrl = `${API_URL}/settings`

/**
 * Retrieve memo settings.
 */
export const FETCH_MEMO_SETTINGS_REQUEST = 'FETCH_MEMO_SETTINGS_REQUEST'
export const FETCH_MEMO_SETTINGS_SUCCESS = 'FETCH_MEMO_SETTINGS_SUCCESS'
export const FETCH_MEMO_SETTINGS_FAILURE = 'FETCH_MEMO_SETTINGS_FAILURE'
export const fetchMemoSettings = (config = {}) => {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_MEMO_SETTINGS_REQUEST,
            FETCH_MEMO_SETTINGS_SUCCESS,
            FETCH_MEMO_SETTINGS_FAILURE,
        ],
    }
}
