import ProjectListView from '../views/ProjectListView'
import { SERVICE_PROJECT_PROJECTS } from '.'

export const componentMap = {}
export const viewMap = {
    'city/project/service/projects': ProjectListView,
}

export const routeServiceMap = {
    'project-details': SERVICE_PROJECT_PROJECTS,
}
