import React from 'react'

import PropTypes from 'prop-types'
import { Translation } from '../Translation'
import { Card } from '../Card'
import { Heading } from '../Heading'
import { Wrapper } from '../Wrapper'

import { renderClasses } from '../../utils'

import avatar from '../../assets/images/avatar.svg'

import './_cardFeedback.scss'

const CardFeedback = ({ heading, text, children, ...classProps }) => {
    return (
        <Card option={['paddingLarge']}>
            <div className={renderClasses('cardFeedback', classProps)}>
                <Wrapper option={['medium', 'noPaddingSmall']}>
                    <div className="cardFeedback_avatar">
                        <img src={avatar} alt="" />
                    </div>
                    {heading && (
                        <Heading el="h2" option={['h1']}>
                            <Translation value={heading} isHtml />
                        </Heading>
                    )}
                    {text && (
                        <p>
                            <Translation value={text} />
                        </p>
                    )}
                    {children && <div className="cardFeedback_children">{children}</div>}
                </Wrapper>
            </div>
        </Card>
    )
}

CardFeedback.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default CardFeedback
