import React from 'react'
import ContentLoader from 'react-content-loader'
import { getLanguages } from 'react-localize-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'

import { GoogleMap } from '../../../../components/GoogleMap'
import { Translation } from '../../../../components/Translation'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Heading } from '../../../../components/Heading'
import { Content } from '../../../../components/Content'
import { HeaderPage } from '../../../../components/HeaderPage'
import { Wrapper } from '../../../../components/Wrapper'
import { Card } from '../../../../components/Card'
import { Section } from '../../../../components/Section'
import { findServiceByType, translation, formatDate } from '../../../../utils'

import { SERVICE_MEMO_ALERTS } from '../../config'

import { AlertListContainer } from '.'

import './_alertCardDetails.scss'

const AlertDetails = ({ alert, category, service, locales }) => {
    const hasGoogleMap = !_.isEmpty(
        _.merge([], alert.map.points, alert.map.polygons, alert.map.polylines)
    )

    return (
        <React.Fragment>
            <Helmet>
                <title itemProp="name">{translation(alert.title)}</title>
                {alert.description && (
                    <meta name="description" content={translation(alert.description)} />
                )}
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content={alert.startDate} />
                <meta property="article:modified_time" content={alert.lastModified} />
                <meta property="article:expiration_time" content={alert.endDate} />
                {locales.map(locale => (
                    <link
                        rel="alternate"
                        href={`${window.location.origin}/${alert.slug[locale.code]}`}
                        hrefLang={locale.code}
                        key={locale.code}
                    />
                ))}
            </Helmet>
            <HeaderPage
                option={['alert']}
                heading={alert.title}
                subheading={<Translation value={category.name} />}
                icon={'memo-' + category.icon}
                breadcrumb={service && service.name}
                featured={alert.featured}
                backLink={service && translation(service.slug)}
            />

            <Wrapper>
                <Section>
                    <Card option={['offset']}>
                        <div className={'cardAlertDetails'}>
                            <Layout option={['gutter']}>
                                <LayoutItem
                                    option={['delay2']}
                                    utility={[hasGoogleMap ? '1/2@from-medium' : '']}
                                >
                                    <Content>
                                        <div className={'alertCardDetails_date'}>
                                            <Heading
                                                option={['h3']}
                                                utility={[alert.featured ? 'red' : 'blue']}
                                                el="time"
                                                datetime={alert.startDate}
                                            >
                                                {formatDate(alert.startDate)}
                                            </Heading>
                                        </div>

                                        {alert.description && (
                                            <Translation isHtml value={alert.description} />
                                        )}
                                    </Content>
                                </LayoutItem>

                                {hasGoogleMap && (
                                    <LayoutItem option={['delay2']} utility={['1/2@from-medium']}>
                                        <div className={'alertCardDetails_map'}>
                                            <GoogleMap
                                                points={alert.map.points}
                                                polygons={alert.map.polygons}
                                                polylines={alert.map.polylines}
                                            />
                                        </div>
                                    </LayoutItem>
                                )}
                            </Layout>
                        </div>
                    </Card>
                </Section>
            </Wrapper>

            <AlertListContainer />
        </React.Fragment>
    )
}

AlertDetails.propTypes = {
    alert: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
    service: PropTypes.object,
    locales: PropTypes.array,
}

const mapStateToProps = state => ({
    service: findServiceByType(state.hub.services.entities, SERVICE_MEMO_ALERTS),
    locales: getLanguages(state.localize),
})

AlertDetails.Placeholder = () => <ContentLoader></ContentLoader>

export default connect(mapStateToProps)(AlertDetails)
