import React, { useCallback } from 'react'
import _ from 'lodash'

import { useFetchItems, responseCustomizer } from '../../../../utils/containers'

import { SwitchTransition } from 'react-transition-group'
import { Fade } from '../../../../components/Fade'
import { useDev, logErrors } from '../../../../utils'
import { NotFound } from '../../../../components/NotFound'

import { fetchProjectBySlug } from '../../actions/projectActions'
import { fetchCategories } from '../../actions/categoryActions'

import { ProjectDetails } from './'

const ProjectDetailsContainer = ({ slug }) => {
    // prepare function to retrieve project by slug
    const useFetchProject = useCallback(() => {
        return fetchProjectBySlug(slug)
    }, [slug])

    const project = useFetchItems(useFetchProject)

    // prepare function to retrieve project category
    // only if project category id exists
    /*
    const useFetchCategory = useCallback(() => {
        if (_.isNil(project.items.category)) {
            return () => {}
        }
        return fetchCategory(project.items.category)
    }, [project.items.category])

    const category = useFetchItems(useFetchCategory)
    */

    const useFetchCategories = useCallback(() => {
        return fetchCategories()
    }, [])

    const categories = useFetchItems(useFetchCategories)

    // merge result in order to retrieve a common error and isLoading props
    const { isLoading, error } = _.mergeWith({}, project, categories, responseCustomizer)
    useDev(logErrors(error))

    return (
        <SwitchTransition>
            <Fade timeout={250} key={isLoading}>
                {project.requestStatus === 404 ? (
                    <NotFound />
                ) : isLoading || !_.isNil(error) ? (
                    <ProjectDetails.Placeholder />
                ) : (
                    <ProjectDetails project={project.items} categories={categories.items} />
                )}
            </Fade>
        </SwitchTransition>
    )
}

export default ProjectDetailsContainer
