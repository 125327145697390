import React from 'react'
import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'
import { Nav } from '../Nav'
import { Sidebar } from '../Sidebar'
import { ScrollToTop } from '../ScrollToTop'
import { Loader } from '../Loader'
import { Footer } from '../Footer'
import { Lightbox } from '../Lightbox'
// import { TrackVisibility } from '../TrackVisibility'

import './_view.scss'

const View = ({ children, ...classProps }) => {

    return (
        <div className={renderClasses('view', classProps)}>
            <Nav />
            <Sidebar layout={classProps.option && classProps.option[0]} />
            <main>
                {children}
                <Footer />
            </main>
            <ScrollToTop />
            <Lightbox />
            <Loader />
        </div>
    )
}

View.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
}

export default View
