import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { getActiveLanguage, getLanguages, withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { createForm, formShape } from 'rc-form'
import _ from 'lodash'
import ContentLoader from 'react-content-loader'
import { Helmet } from 'react-helmet-async'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { openSidebar } from '../../../../actions/sidebarActions'

import {
    findServiceByType,
    resolveServiceLink,
    translation,
    resolveContentStatus,
} from '../../../../utils'

import { FINISHED_CONTENT } from '../../../../config'
import { Heading } from '../../../../components/Heading'
import { Button } from '../../../../components/Button'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Wrapper } from '../../../../components/Wrapper'
import { ProgressBar } from '../../../../components/ProgressBar'
import { Popup } from '../../../../components/Popup'
import { Layout, LayoutItem } from '../../../../components/Layout'
import { Translation } from '../../../../components/Translation'

import { ANONYMOUS_ROLE, USER_ROLE, SERVICE_SURVEY_SURVEYS } from '../../config'
import { hasUserAlreadyParticipated } from '../../utils'

import { Question } from '../Question'

import avatar from '../../../../assets/images/avatar.svg'

import './_survey.scss'

const SurveyDetails = ({
    survey,
    createSurveyAnswer,
    logSurveyAnswer,
    user,
    userSurveys,
    lang,
    locales,
    openSidebar,
    form,
    services,
}) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [currentStatus, setCurrentStatus] = useState(0)
    const [respondAsAnonymous, setRespondAsAnonymous] = useState(false)
    const [formValues, setFormValues] = useState({})
    const [popupIsOpen, setPopupOpen] = useState(false)
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
    const surveyListService = findServiceByType(services, SERVICE_SURVEY_SURVEYS)
    const { url } = resolveServiceLink(surveyListService)

    const startByRole = role => {
        if (role === ANONYMOUS_ROLE) {
            setRespondAsAnonymous(true)
        }
        nextStep()
    }

    const totalSteps = survey.questions.length
    const nextStep = () => {
        form.validateFields(async (formErrors, currentFormValues) => {
            if (formErrors) {
                return false
            }

            if (currentStep === totalSteps) {
                handleSubmitSurvey({ ...formValues, ...currentFormValues })
                setCurrentStatus(currentStatus + 1)
            } else {
                if (currentStep === 0) {
                    setCurrentStatus(currentStatus + 1)
                }

                setCurrentStep(currentStep + 1)
                setFormValues({ ...formValues, ...currentFormValues })
            }
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }

    const prevStep = () => {
        setCurrentStep(currentStep - 1)
        window.scrollTo({ top: 0, behavior: 'smooth' })

        if (currentStep === 1) {
            setCurrentStatus(currentStatus - 1)
        }
    }

    const handleSurveyLogAnswer = (questionType = 'content', answers) => {
        if (_.isEmpty(answers)) {
            return
        }
        const question = survey.questions[currentStep - 1]
        let logAnswer = {
            lang: lang,
            survey: survey.id,
            question: question.id,
        }

        if (questionType === 'radio' || questionType === 'checkbox') {
            logAnswer.answer = answers
        } else {
            logAnswer.content = answers
        }

        if (!respondAsAnonymous && user) {
            logAnswer.user = user.id
        }

        logSurveyAnswer(logAnswer)
    }

    const handleSubmitSurvey = finalFormValues => {
        let surveyAnswer = {
            lang: lang,
            survey: survey.id,
        }
        if (!respondAsAnonymous && user) {
            surveyAnswer.user = user.id
        }

        let responses = []
        survey.questions.map(question => {
            if (_.isEmpty(finalFormValues[question.id])) {
                return null
            }

            let questionAnswer = { id: question.id }
            if (question.type === 'textarea') {
                questionAnswer.content = finalFormValues[question.id]
            } else {
                questionAnswer.values = finalFormValues[question.id]
            }
            responses.push(questionAnswer)
            return null
        })

        surveyAnswer.responses = responses
        createSurveyAnswer(surveyAnswer)
        setHasBeenSubmitted(true)
    }

    const popup = (
        <Popup
            status={[popupIsOpen ? 'open' : '']}
            heading="survey.details.popup.heading"
            description="survey.details.popup.description"
            onClick={() => setPopupOpen(!popupIsOpen)}
        >
            <Layout option={['gutterSmall']}>
                <LayoutItem option={['auto']}>
                    <Button option={['white', 'warning', 'inline']} href={url}>
                        <Translation value="survey.details.popup.quit" />
                    </Button>
                </LayoutItem>
                <LayoutItem option={['auto']}>
                    <Button onClick={() => setPopupOpen(!popupIsOpen)}>
                        <Translation value="survey.details.popup.continue" />
                    </Button>
                </LayoutItem>
            </Layout>
        </Popup>
    )
    const hasAlreadyParticipated = hasUserAlreadyParticipated(userSurveys, survey.id)
    const status = resolveContentStatus(survey.startDate, survey.endDate)

    return (
        <div className="survey">
            <Helmet>
                <title itemProp="name">{translation(survey.title)}</title>
                <meta name="description" content={translation(survey.description)} />
                <meta property="og:type" content="article" />
                <meta property="article:published_time" content={survey.startDate} />
                <meta property="article:modified_time" content={survey.lastModified} />
                <meta property="article:expiration_time" content={survey.endDate} />
                {locales.map(locale => (
                    <link
                        rel="alternate"
                        href={`${window.location.origin}/${survey.slug[locale.code]}`}
                        hrefLang={locale.code}
                        key={locale.code}
                    />
                ))}
            </Helmet>
            <ProgressBar
                option={['fixed']}
                step={currentStep - 1}
                total={totalSteps}
                data="survey.details.title"
            />
            <TransitionGroup>
                <CSSTransition
                    key={currentStatus}
                    classNames="has-transition"
                    timeout={300}
                    appear
                    exit
                >
                    <div className="survey_container">
                        {status === translation(FINISHED_CONTENT) ? (
                            <Fragment>
                                <div className="survey_close">
                                    <Button option={['circle', 'white']} icon="close" href={url} />
                                </div>
                                <div className="survey_main -intro">
                                    <div className="survey_background"></div>
                                    <Wrapper>
                                        <CardFeedback
                                            heading="survey.details.finished.title"
                                            text="survey.details.finished.description"
                                        >
                                            <Button
                                                option={['blue', 'iconLeft']}
                                                icon="arrow-left"
                                                href={url}
                                            >
                                                <Translation value="survey.details.submitted.button" />
                                            </Button>
                                        </CardFeedback>
                                    </Wrapper>
                                </div>
                            </Fragment>
                        ) : hasAlreadyParticipated ? (
                            <Fragment>
                                <div className="survey_close">
                                    <Button option={['circle', 'white']} icon="close" href={url} />
                                </div>
                                <div className="survey_main -intro">
                                    <div className="survey_background"></div>
                                    <div className="survey_content">
                                        <Wrapper>
                                            <div className="survey_avatar">
                                                <img src={avatar} alt="" />
                                            </div>
                                            <Heading el="h1" option={['h1']}>
                                                <Translation value="survey.details.already_completed.title" />
                                            </Heading>
                                            <div className="survey_buttons">
                                                <div className="survey_button">
                                                    <Button
                                                        option={['blue', 'iconLeft']}
                                                        icon="arrow-left"
                                                        href={url}
                                                    >
                                                        <Translation value="survey.details.submitted.button" />
                                                    </Button>
                                                </div>
                                            </div>
                                        </Wrapper>
                                    </div>
                                </div>
                            </Fragment>
                        ) : hasBeenSubmitted ? (
                            <Fragment>
                                <div className="survey_close">
                                    <Button option={['circle', 'white']} icon="close" href={url} />
                                </div>
                                <div className="survey_main -intro">
                                    <div className="survey_background"></div>
                                    <Wrapper>
                                        <div className="survey_card">
                                            <CardFeedback
                                                heading="survey.details.submitted.title"
                                                text="survey.details.submitted.description"
                                            >
                                                <Button
                                                    option={['blue', 'iconLeft']}
                                                    icon="arrow-left"
                                                    href={url}
                                                >
                                                    <Translation value="survey.details.submitted.button" />
                                                </Button>
                                            </CardFeedback>
                                        </div>
                                    </Wrapper>
                                </div>
                            </Fragment>
                        ) : currentStep === 0 ? (
                            <Fragment>
                                <div className="survey_close">
                                    <Button option={['circle', 'white']} icon="close" href={url} />
                                </div>
                                <div className="survey_main -intro">
                                    <div className="survey_background"></div>
                                    <div className="survey_content">
                                        <Wrapper>
                                            <div className="survey_avatar">
                                                <img src={avatar} alt="" />
                                            </div>
                                            <Heading el="h1" option={['h1']}>
                                                <Translation value={survey.title} />
                                            </Heading>
                                            <div className="survey_buttons">
                                                <div className="survey_button">
                                                    {user ? (
                                                        <Button
                                                            option={['white']}
                                                            onClick={() => startByRole(USER_ROLE)}
                                                        >
                                                            <Translation
                                                                value="survey.details.respond.as_user.title"
                                                                data={{ user: user.firstname }}
                                                            />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            option={['white']}
                                                            onClick={openSidebar}
                                                        >
                                                            <Translation value="survey.details.login.title" />
                                                        </Button>
                                                    )}
                                                </div>
                                                <div className="survey_button">
                                                    {survey.allowAnonymous && (
                                                        <Button
                                                            onClick={() =>
                                                                startByRole(ANONYMOUS_ROLE)
                                                            }
                                                        >
                                                            <Translation value="survey.details.respond.anonymously.title" />
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="survey_wrap">
                                                <div className="survey_text">
                                                    <Translation
                                                        value={survey.description}
                                                        isHtml
                                                    />
                                                </div>
                                            </div>
                                        </Wrapper>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="survey_close">
                                    <Button
                                        option={['circle', 'white']}
                                        icon="close"
                                        onClick={() => setPopupOpen(!popupIsOpen)}
                                    />
                                </div>
                                <div className="survey_main -question">
                                    <Question
                                        question={survey.questions[currentStep - 1]}
                                        nextQuestion={nextStep}
                                        prevQuestion={prevStep}
                                        logAnswer={handleSurveyLogAnswer}
                                        form={form}
                                        formValues={formValues}
                                        isLast={currentStep === totalSteps ? true : false}
                                    />
                                </div>
                                {popup}
                            </Fragment>
                        )}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
}

SurveyDetails.propTypes = {
    form: formShape,
    survey: PropTypes.shape({
        questions: PropTypes.array.isRequired,
    }).isRequired,
    userSurveys: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    openSidebar: PropTypes.func,
    locales: PropTypes.array,
}

SurveyDetails.Placeholder = () => <ContentLoader></ContentLoader>

const mapStateToProps = state => ({
    lang: getActiveLanguage(state.localize).code,
    locales: getLanguages(state.localize),
    user: state.hub.auth.isAuthenticated ? state.hub.auth.user : false,
    services: state.hub.services.entities,
})

const mapDispatchToProps = dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(createForm()(SurveyDetails)))
