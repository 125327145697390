import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import { Wrapper } from '../../../../components/Wrapper'
import { CardFeedback } from '../../../../components/CardFeedback'
import { Button } from '../../../../components/Button'

import { Translation } from '../../../../components/Translation'

import { AlertSlider, AlertTable } from './'

const AlertList = ({ alerts, categories }) => {
    if (!alerts.length) {
        return (
            <Wrapper>
                <CardFeedback text="memo.alerts.listing.placeholder.description">
                    <Button option={['blue', 'iconLeft']} icon="arrow-left" href="/">
                        <Translation value="memo.alerts.listing.placeholder.button" />
                    </Button>
                </CardFeedback>
            </Wrapper>
        )
    }

    //Retrieve last 5 alerts for the Alertslider
    const recentAlerts = alerts.slice(0, 5)

    return (
        <div>
            {recentAlerts.length > 0 && (
                <AlertSlider
                    name={<Translation value="memo.alerts.last.title" />}
                    alerts={recentAlerts}
                    categories={categories}
                    showHeader={true}
                />
            )}

            <AlertTable
                name={<Translation value="memo.alerts.all.title" />}
                alerts={alerts}
                categories={categories}
            />
        </div>
    )
}

AlertList.propTypes = {
    alerts: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
}

AlertList.Placeholder = () => <ContentLoader></ContentLoader>

export default AlertList
